import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { styles } from "../Css/styles";
import SliderButtons from "./Events/SlugTag";
import SearchBar from "./Events/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import { setClearEventData, setEventLoading } from "../../../redux/features/valuemarketSlice";
import { getEventsList } from "../../../redux/services/valuebetmarketServices";
import Events from "./Events";

export default function Index({ mobView, onSwitch, passDatatoParent }) {
  const order = useSelector(
    (state) => state.valuemarket.orderValue
  );
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [noMoreData, setNoMoreData] = useState(false);
  let availableLimit = parseInt(((window.innerHeight / 200) * 3) + 4)
  let calcuateLimit = availableLimit > 10 ? availableLimit : 10
  const {
    EventLoading: eventLoading,
    eventCheck,
    noDataKeyWord,
    subCategory,
    eventsData: events,
    toggleFilter,
    favEvents,
    showFav
  } = useSelector((state) => state.valuemarket);
  const baseParams = useMemo(
    () => ({
      ...(showFav ? {} : { limit: calcuateLimit }),
      active: true,
      archived: false,
      closed: false,
      order: order,
      ascending: order === 'endDate',
      offset: 0,
      tag_slug: subCategory || undefined,
    }),
    [subCategory, order, calcuateLimit, showFav]
  );


  const getParams = (initial = false) => {
    const params = new URLSearchParams();
    if (search) {
      let search_order = ""
      if (order === 'volume24hr') search_order = 'volume_24hr';
      else if (order === 'startDate') search_order = 'start_date';
      else if (order === 'endDate') search_order = 'end_date';
      else search_order = order;
      
      params.append('_c', 'all');
      params.append('_q', search || noDataKeyWord);
      params.append('_s', `${search_order === 'end_date' ? search_order + ':asc' : search_order + ':desc'}`);
      params.append('_p', initial ? 1 : offset/calcuateLimit + 1 || 1);

    } else {
      let new_params = { ...baseParams, offset: initial ? 0 : offset }
      Object.entries(new_params).forEach(([key, value]) => {
        if (value !== undefined) params.append(key, value);
      });
    }

    if (showFav && favEvents && favEvents.length > 0) {
      favEvents.forEach(id => params.append('id', id));
    }
    return params;
  };


  const fetchInitialData = async () => {
    setOffset(0);
    scrollToTop()
    setNoMoreData(false)
    dispatch(setEventLoading(true));
    dispatch(setClearEventData())
    try {
      const params = getParams(true);
      await dispatch(getEventsList(params));
    } catch (error) {
      console.error("Error fetching initial events:", error);
    } finally {
      dispatch(setEventLoading(false));
    }
  };

  useEffect(() => {
    if ((subCategory || search || order || showFav) && !eventLoading) {
      fetchInitialData();
    }
    // eslint-disable-next-line
  }, [subCategory, search, order, showFav]);

  const loadMoreData = async () => {
    setLoadingMore(true);
    try {
      const params = getParams();
      await dispatch(getEventsList(params, true));
      if (eventCheck) {
        setNoMoreData(true);
      } 
    } catch (error) {
      console.error("Error fetching more events:", error);
      setNoMoreData(true);
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    if(offset > 0) loadMoreData();
    // eslint-disable-next-line
  },[offset])

  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (!container) return;
      const { scrollTop, scrollHeight, clientHeight } = container;
      let scrolledPercentage = scrollHeight - scrollTop <= clientHeight + 100 
      if (scrolledPercentage && !showFav && !loadingMore && !noMoreData && !eventLoading) {
        setOffset((prevOffset) => prevOffset + calcuateLimit);
      }
   } 
    const container = scrollContainerRef.current;
    if (container) container.addEventListener("scroll", handleScroll);
    return () => container?.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line
  }, [showFav, loadingMore, noMoreData, eventLoading]);

  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <Grid
      container
      spacing={2}
      mt="71px"
      mx={'10px'}
      sx={styles.responsiveContainerStyles(toggleFilter, mobView)}
    >
      <SearchBar
        order={order}
        handleSearch={handleSearch}
      />

      <SliderButtons/>

      <Events
        events={events}
        eventLoading={eventLoading}
        loadingMore={loadingMore}
        noMoreData={noMoreData}
        onSwitch={onSwitch}
        passDatatoParent={passDatatoParent}
        scrollContainerRef={scrollContainerRef}
      />
    </Grid>
  );
}
