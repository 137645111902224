import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import LockOutlined from '@mui/icons-material/LockOutlined';
import { errorNotification, successNotification } from '../../../../../../Components/CustComp/PPHAlert';
import { postValuebet, UPDATE_LEADERBOARD_STATUS_URL } from '../../../../../../API-Handle/services/auth_curd';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';
import Switch from "react-switch";
import CustTooltip from '../../../../../../Components/CustComp/CustTooltip';

const LeaderboardConf = ({ clubInfo }) => {
  const { fetchSingleClub, setRootLoader } = useContext(ValuebetContext);

  const handleCheckboxChange = async (event) => {
    setRootLoader(true);
    let data = { lead_type: event.target.name }
    let res = await postValuebet(UPDATE_LEADERBOARD_STATUS_URL + clubInfo.id, data);
    if (res) {
      successNotification(res.message);
      fetchSingleClub(clubInfo.id);
      setRootLoader(false);
    }
  };

  const toggleReportStatus = async (event) => {
    setRootLoader(true);
    let data = { report_toggle: event }
    let res = await postValuebet(UPDATE_LEADERBOARD_STATUS_URL + clubInfo.id, data);
    if (res) {
      successNotification(res.message);
      fetchSingleClub(clubInfo.id);
      setRootLoader(false);
    }
  };

  const toggleTrackingType = async (e) => {
    setRootLoader(true);
    let tracking_type = 'NET';
    if (e) tracking_type = "WINS";
    let data = { tracking_type }
    let res = await postValuebet(UPDATE_LEADERBOARD_STATUS_URL + clubInfo.id, data)
    if (res) {
      successNotification(res.message);
      fetchSingleClub(clubInfo.id);
      setRootLoader(false);
    }
  }

  const wantMessage = () => {
    {/* // TODO: Temp Unlocked */ }
    // if (!clubInfo.level_details.leaderboard) {
    //   errorNotification("Upgrade Club to Unlock!")
    // }
  }

  return (
    <div className='d-flex flex-wrap gap-1 justify-content-between' onClick={wantMessage}>
      <div className='me-3'>
        {/* // TODO: Temp Unlocked */}
        {/* <label className='text-white'>Display Leaderboard:</label> {!clubInfo.level_details.leaderboard && <LockOutlined fontSize='12' className='icon-bet-no mb-1' />} */}
        <label className='text-white d-flex'>Display Leaderboard: {!clubInfo.level_details.leaderboard && <div className='d-flex'><LockOutlined fontSize='small' className='icon-bet-no' /><div className="text-warning font-size-10px mb-1">Preview</div></div>}</label>
        <FormGroup row className='mt-2 pt-1'>
          <FormControlLabel
            control={
              <Checkbox
                checked={clubInfo.leaderboard_status.daily}
                onChange={handleCheckboxChange}
                name="Daily"
                sx={{fontSize: "1rem", marginLeft: '-10px'}}
              // disabled={!clubInfo.level_details.leaderboard} {/* // TODO: Temp Unlocked */}
              />
            }
            label="Daily"
            sx={{ color: "white", margin: '0px'}}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={clubInfo.leaderboard_status.weekly}
                onChange={handleCheckboxChange}
                name="Weekly"
                sx={{fontSize: "1rem"}}
              // disabled={!clubInfo.level_details.leaderboard} {/* // TODO: Temp Unlocked */}
              />
            }
            label="Weekly"
            sx={{ color: "white", margin: '0px' }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={clubInfo.leaderboard_status.monthly}
                onChange={handleCheckboxChange}
                name="Monthly"
                sx={{fontSize: "1rem"}}
              // disabled={!clubInfo.level_details.leaderboard} {/* // TODO: Temp Unlocked */}
              />
            }
            label="Monthly"
            sx={{ color: "white", margin: '0px' }}
          />
        </FormGroup>
      </div>
      <div className='leaderboard-switch'>
        <div className='text-white d-flex align-items-center justify-content-between'>
          Reports:
          <Switch
            onChange={toggleReportStatus}
            checked={clubInfo?.leaderboard_status?.report_toggle}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
            height={22}
            width={62}
            onColor="#008000"
            onHandleColor="#1cca1c"
            offColor="#ffac8b"
            offHandleColor="#e03f00"
            borderRadius={8}
            uncheckedIcon={
              <div className='d-flex justify-content-center align-items-center h-100 pe-1 text-dark font-size-12px'>
                Off
              </div>
            }
            checkedIcon={
              <div className='d-flex justify-content-center align-items-center h-100 ps-1 text-white font-size-12px'>
                On
              </div>
            }
            className="react-switch ps-2"
          />
          <CustTooltip
            id={clubInfo.id}
            text={"Turning on Reports will allow members to see each other's bets and performance"}
            customClass="d-flex align-items-center ms-2 d-400-none"
          />
        </div>
        <div className='text-white d-flex align-items-center justify-content-between'>
           Tracking:
          <Switch
            onChange={toggleTrackingType}
            checked={clubInfo?.leaderboard_status?.tracking_type === 'WINS'}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
            height={22}
            width={62}
            onColor="#008000"
            onHandleColor="#1cca1c"
            offColor="#ffac8b"
            offHandleColor="#e03f00"
            borderRadius={8}
            uncheckedIcon={
              <div className='d-flex justify-content-center align-items-center h-100 pe-2 text-dark font-size-11px'>
                NET
              </div>
            }
            checkedIcon={
              <div className='d-flex justify-content-center align-items-center h-100 ps-2 text-white font-size-11px'>
                WINS
              </div>
            }
            className="react-switch ps-2"
          />
          <CustTooltip
            id={clubInfo.id}
            text={"WINs only tracks winning days for Weekly/Monthly Leaderboard. NET tracks both winning and losing days."}
            customClass="d-flex align-items-center ms-2 d-400-none"
          />
        </div>
      </div>
    </div>
  );
};

export default LeaderboardConf;
