import React, { useState, useEffect, useContext } from 'react'
import { Modal, Row, Card, CardHeader, CardBody, Badge, } from "reactstrap"
import { GET_NEWS, getValuebet } from '../../API-Handle/services/auth_curd';
import ValuebetContext from '../../context/Valuebet/ValuebetContext';

export default function News({ newsModal, newsToggle }) {
  const [data, setData] = useState(null);
  const {getUserInfo } = useContext(ValuebetContext)

  const getNews = async () => {
    let res = await getValuebet(GET_NEWS)
    if (res) {
      setData(res);
      getUserInfo();
    }
  }

  useEffect(() => {
    if (newsModal){
      getNews()

    }
  }, [newsModal])

  return (
    <Modal isOpen={newsModal} toggle={newsToggle} className="modal-dialog-centered modal-xl">
      <div className="modal-header bg-vb-dark">
        <h5 className="modal-title my-0">Valuebet.App Latest Updates</h5>
        <button onClick={newsToggle} type="button" className="text-white btn-close" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div className='modal-body'>
        {data ?
          data?.length ? data.map((item, index) => {
            return (
              <Card className='bg-vb-dark-light' key={index}>
                <CardBody className="pb-0">
                  <h5 className="text-capitalize l-h-30 d-flex flex-wrap align-items-center gap-2">
                    <div className='valuebet-bg-color px-2 text-white me-1 rounded font-size-16px'>{item.news_date}</div>
                    <small className='text-warning'>{item.heading}</small>
                  </h5>
                  <pre className="pre-space-wrap text-white">{item.description}</pre>
                </CardBody>
              </Card>
            )
          }) : "No News Available!"
          : <div className='d-flex justify-content-center my-4'>
            <div className="spinner-grow text-primary" role="status"></div>
          </div>}
      </div>
    </Modal>
  )
}
