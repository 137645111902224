import React, { useState, useEffect } from 'react'
import MemberLimitTable from './MemberLimitTable'
import { getValuebet, GET_PLAYER_BET_LIMIT_URL, postValuebet, UPDATE_PLAYER_BET_LIMIT_URL } from '../../../../../../../../API-Handle/services/auth_curd';
import { errorNotification, successNotification } from '../../../../../../../../Components/CustComp/PPHAlert';

function BetLimit({ club, item }) {
  const [playerBetLimits, setPlayerBetLimits] = useState(null);
  const [isPlayerLimited, setIsPlayerLimited] = useState(false);

  useEffect(() => {
    getPlayerBetLimits();
    // eslint-disable-next-line
  }, []);

  const getPlayerBetLimits = async () => {
    let request_url = `${GET_PLAYER_BET_LIMIT_URL}?club_id=${club.id}&user_id=${item?.id}`;
    let limits = await getValuebet(request_url);
    if (limits) {
      setIsPlayerLimited(limits?.bet_limits_enabled)
      let bet_limits = await JSON.parse(JSON.stringify(limits.bet_limits));
      let player_bet_limits = [];
      Object.keys(bet_limits).forEach((element) => {
        player_bet_limits.push(bet_limits[element]);
      });
      setPlayerBetLimits(player_bet_limits);
    }
  };


  const savePlayerBetLimits = async () => {
    let is_error = false;
    playerBetLimits.forEach((it) => {
      if (it.max_bet_all === "" || it.max_bet_all < 50 ||
        it.max_bet_live === "" || it.max_bet_live < 50 ||
        it.max_parlay_all === "" || it.max_parlay_all < 50 ||
        it.max_parlay_live === "" || it.max_parlay_live < 50) {
        is_error = true;
      }
    })

    if (is_error) {
      errorNotification("Please enter valid inputs. Inputs are should not be less-than 50.");
      return;
    }
    let data = {
      "club_id": club.id,
      "user_id": item.id,
      "bet_limits_enabled": isPlayerLimited,
      "bet_limits": playerBetLimits
    }
    let res = await postValuebet(`${UPDATE_PLAYER_BET_LIMIT_URL}`, data)
    if (res) {
      successNotification(res.message);
    }
  }

  const toggleLimitedStatus = async (status) => {
    let data = {
      "club_id": club.id,
      "user_id": item.id,
      "bet_limits_enabled": status,
    }
    let res = await postValuebet(`${UPDATE_PLAYER_BET_LIMIT_URL}`, data)
    if (res) {
      setIsPlayerLimited(status)
      successNotification(res.message);
    };
  }

  return (
    <div className={`p-3 card rounded bg-dark mt-3`}>
    {/* // TODO: Temp Unlocked */}
    {/* <div className={`p-3 card rounded bg-dark mt-3 ${!club?.level_details?.custom_limits && "section-disable"}`}> */}
      {/* {!club?.level_details?.custom_limits && <div className='text-warning py-1 px-2 mb-1 bg-vb-dark rounded fw-bold'>Upgrade Club for Access</div>} */}
      <div className='align-item-center'>
        <input type="checkbox" checked={isPlayerLimited} onChange={(e) => toggleLimitedStatus(e.target.checked)} id="member-limited"/>
        <label
          htmlFor="member-limited"
          className="form-label text-white font-size-16px"
        >
          Limited
        </label>
      </div>

      <MemberLimitTable
        height={450}
        limits={playerBetLimits}
        setUpdatedLimits={setPlayerBetLimits}
        // isDisable={!club?.level_details?.custom_limits} {/* // TODO: Temp Unlocked */}
        saveLimits={savePlayerBetLimits}
      />
    </div>
  )
}

export default BetLimit
