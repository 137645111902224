import React, { useEffect } from "react";
import NavigationBar from "../../../Components/SportsbookHeader/NavigationBar";
import EventsWrapper from "./EventsWrapper";
import { useLocation } from "react-router-dom";
import Bets from "../Layout/Bets";
import { useDispatch, useSelector } from "react-redux";
import { setMobileScreen } from "../../../redux/features/valuemarketSlice";
import Sidebar from "../Layout/Sidebar";
import { getFavEventList, getPlayerMarketLimitList } from "../../../redux/services/valuebetmarketServices";

function Index() {
  const dispatch = useDispatch();
  const location = useLocation();
  const valueMarketDetails = useSelector(
    (state) => state.valuemarket.valueMarketDetails
  );
  const mobileScreen = useSelector((state) => state.valuemarket.mobileScreen);
  const { bets } = valueMarketDetails;

  useEffect(() => {
    let club_id = location?.state?.club?.id;
    dispatch(getPlayerMarketLimitList(club_id));
    dispatch(getFavEventList())
    // eslint-disable-next-line
  }, []);


  return (
    <React.Fragment>
      <NavigationBar />
      <div className="market-container-fluid">
        <Sidebar mobView={mobileScreen.step1} />
        <EventsWrapper mobView={mobileScreen.step2}/>
        <Bets mobView={mobileScreen.step3} openTab={"step2"}/>
        {mobileScreen.step2 && bets.length !== 0 && (
          <div className="bet-button-box position-absolute">
            <button
              className="bet-btn"
              onClick={() => dispatch(setMobileScreen("step3"))}
            >
              Bets ({bets.length})
            </button>
          </div>
        )}

        {mobileScreen.step1 && (
          <div className="bet-button-box position-absolute">
            <button
              className="bet-btn"
              onClick={() => dispatch(setMobileScreen("step2"))}
            >
              Continue
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Index;
