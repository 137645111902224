import React, { useEffect, useState } from 'react'
import SmSpinner from '../../../../../../../Components/CustLoader/SmSpinner';
import { successNotification } from '../../../../../../../Components/CustComp/PPHAlert';
import { postValuebet, UPDATE_PLAYER_BET_LIMIT_URL } from '../../../../../../../API-Handle/services/auth_curd';

const Index = ({ item, club, limits, toggleModal }) => {
  const [marketLimits, setMarketLimits] = useState({
    min_bet_all: '',
    max_bet_all: ''
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setMarketLimits(limits)
  }, [limits])

  const updateValue = (value, field) => {
    const parsedValue = value === '' ? '' : parseFloat(value);
    setMarketLimits(prev => ({
      ...prev,
      [field]: parsedValue
    }));
  };

  const handleSave = async () => {
      setLoader(true);
      let data = {
        "club_id": club.id,
        "user_id": item.id,
        "bet_limits_enabled": item.bet_limits_enabled,
        "market_bet_limits": marketLimits
      }
      const res = await postValuebet(UPDATE_PLAYER_BET_LIMIT_URL, data);
      if (res) {
        successNotification(res.message);
        toggleModal();
      }
      setLoader(false);
  };

  return (
    <>
      <div className="row g-3 mt-1">
        <div className="col-12 col-sm-6">
          <div className="text-nowrap text-white">Min Bet</div>
          <div className="mt-2">
            <input
              type="number"
              min={0}
              value={marketLimits.min_bet_all}
              className="form-control form-control-sm bg-dark text-white border-secondary"
              placeholder="Amount"
              onChange={(e) => updateValue(e.target.value, "min_bet_all")}
            />
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <div className="text-nowrap text-white">Max Bet</div>
          <div className="mt-2">
            <input
              type="number"
              min={0}
              value={marketLimits.max_bet_all}
              className="form-control form-control-sm bg-dark text-white border-secondary"
              placeholder="Amount"
              onChange={(e) => updateValue(e.target.value, "max_bet_all")}
            />
          </div>
        </div>
      </div>
      <div className="text-end mt-4">
        {loader ?
          <button type="button" className="btn btn-danger btn-orange btn-sm">
            <SmSpinner inline={true} />
          </button>
          :
          <button
            type="button"
            className="btn btn-danger btn-orange btn-sm"
            onClick={handleSave}
          >
            Save changes
          </button>
        }
        &nbsp;
        <button
          onClick={toggleModal}
          type="button"
          className="btn btn-secondary btn-sm"
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default Index;