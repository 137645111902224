import React from "react";
import { IconButton } from "@mui/material";
import { styles } from "../../Css/styles";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import { setShowFav } from "../../../../redux/features/valuemarketSlice";

export default function FavouriteEvent() {
  const dispatch = useDispatch();
  const { showFav } = useSelector((state) => state.valuemarket);
  const handleShowFav = () => dispatch(setShowFav());

  return (
    <IconButton sx={{...styles.filterOption,borderRadius:'5px',flexGrow:1,background:showFav ? '#e03f00' : '#ffffff14'}} onClick={() => handleShowFav()}>
       <StarIcon/>
    </IconButton>
  );
}
