import React, { useContext, useEffect, useState } from 'react'
import { GET_CLUB_ODD_LIMITS_URL, UPDATE_ODD_LIMIT_URL, getValuebet, postValuebet } from '../../../../../../API-Handle/services/auth_curd'
import { errorNotification, successNotification } from '../../../../../../Components/CustComp/PPHAlert'
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext'
import SmSpinner from '../../../../../../Components/CustLoader/SmSpinner';

const isValid = /^[0-9]+$/;

const validInt = (num) => {
    let val = '';
    if (isValid.test(num)) val = num;
    return val;
}

export default function Index({ club, toggleClubSetting }) {
    const [oddLimits, setOddLimits] = useState([])
    const [loader, setLoader] = useState(false)
    const { fetchSingleClub } = useContext(ValuebetContext);

    const getClubOddLimits = async() => {
        let res = await getValuebet(GET_CLUB_ODD_LIMITS_URL+club.id);
        if (res) {
            let club_odd_limits = [];
            Object.keys(res).forEach(element => {
                club_odd_limits.push(res[element]);
            });
            setOddLimits(club_odd_limits)
        }
    }

    useEffect(() => {
        getClubOddLimits();
        // eslint-disable-next-line
    }, [])

    const saveOddLimits = async () => {
        setLoader(true)
        let is_error = false;
        oddLimits.forEach((item) => {
            if (item.neg_odd_limits === "" || item.neg_odd_limits < 100 ||
                item.pos_odd_limits === "" || item.pos_odd_limits < 100 ) {
                is_error = true;
            }
        })

        if (is_error) {
            setLoader(false);
            errorNotification("Please enter valid inputs. Inputs are should not be less-than 100.");
            return;
        }

        let data = {
            "club_id": club.id,
            "odd_limits": oddLimits
        }
        let res = await postValuebet(`${UPDATE_ODD_LIMIT_URL}`, data)
        if (res) {
            successNotification(res.message);
            fetchSingleClub(club.id)
        }
        setLoader(false)
    }

    const updateAllVal = (e, type) => {
        if (!isValid.test(e.target.value)){
            e.target.value = "";
        }
        let new_limits = [...oddLimits];
        new_limits.forEach((ele) => {
            if (type === "NEG") {
                ele.neg_odd_limits = validInt(e.target.value);
            } else if (type === "POS") {
                ele.pos_odd_limits = validInt(e.target.value);
            }
        })
        setOddLimits(new_limits)
    }

    const updateVal = (item, val, type) => {
        let new_limits = [...oddLimits];
        new_limits.forEach((ele) => {
            if (ele.league === item.league) {
                if (type === "NEG") {
                    ele.neg_odd_limits = validInt(val);
                } else if (type === "POS") {
                    ele.pos_odd_limits = validInt(val);
                } else if (type === "ACTIVE") {
                    ele.is_activate = val;
                }
            }
        })
        setOddLimits(new_limits)
    }

    return (
        <div className="tab-content">
            <div className="tab-pane fade active show" id="odd-limits">
                
                {/* // TODO: Temp Unlocked */}
                {/* {!club?.level_details?.custom_limits && <div className='text-warning py-1 px-2 mb-1 bg-dark rounded fw-bold'>Upgrade Club for Access</div>} */}
                <div className="overflow-x overflow-y" style={{ maxHeight: window.innerHeight - club?.level_details?.custom_limits ? 280 : 320 }}>
                {/* // TODO: Temp Unlocked */}
                {/* <table className={`table table-custom-dark ${!club?.level_details?.custom_limits && "section-disable"}`}> */}
                <table className={`table table-custom-dark`}>
                    <thead>
                        <tr>
                            <th scope="col" className="align-top">Sports</th>
                            <th scope="col" className="align-top">Activate</th>
                            <th scope="col" className="align-top text-nowrap">
                                Hide (-) Odds
                                <div className="input-group input-group-xs">
                                    <span className="input-group-text bg-secondary text-white" id={`neghad`}>-</span>
                                    <input type="text" className="form-control bg-dark text-white" placeholder="Value: Apply All" onChange={(e) => updateAllVal(e, "NEG")} aria-describedby={`neghad`}/>
                                </div>
                            </th>
                            <th scope="col" className="align-top">
                                Hide (+) Odds
                                <div className="input-group input-group-xs">
                                    <span className="input-group-text bg-secondary text-white" id={`poshad`}>+</span>
                                    <input type="text" className="form-control bg-dark text-white" placeholder="Value: Apply All" onChange={(e) => updateAllVal(e, "POS")} aria-describedby={`poshad`}/>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {oddLimits.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.league}</td>
                                    <td><input type="checkbox" checked={item.is_activate} onChange={(e) => updateVal(item, e.target.checked, "ACTIVE")} /></td>
                                    <td>
                                        <div className="input-group input-group-xs">
                                            <span className="input-group-text bg-secondary text-white" id={`neg${index}`}>-</span>
                                            <input type="text" value={item.neg_odd_limits} onChange={(e) => updateVal(item, e.target.value, "NEG")} className="form-control bg-dark text-white" placeholder="Value" aria-describedby={`neg${index}`} />
                                        </div>
                                    </td>
                                        
                                    <td>
                                    <div className="input-group input-group-xs">
                                        <span className="input-group-text bg-secondary text-white" id={`pos${index}`}>+</span>
                                        <input type="text" value={item.pos_odd_limits} onChange={(e) => updateVal(item, e.target.value, "POS")} className="form-control bg-dark text-white" placeholder="Value" aria-describedby={`pos${index}`}/>
                                    </div>
                                    </td>
                                        
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <div className="text-end mt-4">
                {/* // TODO: Temp Unlocked */}
                {loader ? <button type="button" className="btn btn-danger btn-orange btn-sm"><SmSpinner inline={true} /></button>
                    // : <button type="button" disabled={!club?.level_details?.custom_limits} className="btn btn-danger btn-orange btn-sm" onClick={saveOddLimits}>Save changes</button>}
                    : <button type="button" className="btn btn-danger btn-orange btn-sm" onClick={saveOddLimits}>Save changes</button>}

                &nbsp;
                <button onClick={toggleClubSetting} type="button" className="btn btn-secondary btn-sm">Cancel</button>
            </div>
            </div>
        </div>
    )
}
