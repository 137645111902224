import { OFFLINE_ODD } from "../constant";

export const formatToMillions = (value) => {
  if (value >= 1e6) {
    return (value / 1e6).toFixed(0) + "M";
  }
  return value?.toString();
};

export const formatValue = (value) => {
  if (value >= 1000) {
    return value.toLocaleString();
  }
  return value.toString();
};

export const manipulateOddValue = (odd_money, limit_selecter) => {
  if (odd_money < 0) {
    if (-limit_selecter["neg_odd_limits"] >= odd_money) {
      odd_money = OFFLINE_ODD;
    }
  }
  if (odd_money > 0) {
    if (limit_selecter["pos_odd_limits"] <= odd_money) {
      odd_money = OFFLINE_ODD;
    }
  }
  return odd_money;
};

export const probabilityToAmericanOdds = (probability,limit_selecter) => {
  probability = probability * 100;
  let odds;
  if (probability === 0 || probability === 100) return OFFLINE_ODD;
  if (probability <= 50) {
    // Calculate positive odds
    odds = 100 / (probability / 100) - 100;
  } else {
    // Calculate negative odds
    odds = (probability / (1 - probability / 100)) * -1;
  }
  return manipulateOddValue(Math.round(odds), limit_selecter); // need to return integer only
};

export const safeParseJSON = (input) => {
  if (typeof input === "string") {
    try {
      return JSON.parse(input);
    } catch (error) {
      console.warn("Failed to parse JSON:", error);
      return []; // Return an empty array as a fallback
    }
  }
  return input; // If it's already parsed, return as is
};

export const applyVigTax = (row, activeClub) => {
  let a = row.bestAsk;
  let b = 1 + row.spread - row.bestAsk;
  if (a < 0.99 || b < 0.99) {
    if (activeClub?.hard_pricing) {
      a += activeClub?.vig_tax / 100;
      b += activeClub?.vig_tax / 100;
      if(a > 0.99) a = 0.99;
      if(b > 0.99) b = 0.99;
    } else if (activeClub?.vig_tax > row.spread) {
      a = a - row.spread + activeClub?.vig_tax / 100;
      b = b - row.spread + activeClub?.vig_tax / 100;
      if(a > 0.99) a = 0.99;
      if(b > 0.99) b = 0.99;
    }
  }
  a = a.toFixed(4);
  b = b.toFixed(4);
  return [a, b];
};
