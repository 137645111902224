import React, { useEffect, useState } from "react";
import Bets from "../Layout/Bets";
import { useDispatch, useSelector } from "react-redux";
import NavigationBar from "../../../Components/SportsbookHeader/NavigationBar";
import { setMobileScreen } from "../../../redux/features/valuemarketSlice";
import EventDetailsCard from "./EventDetailsCard";
import { useLocation, useParams } from "react-router";
import { getValuebet, POLYMARKETS_LISTS } from "../../../API-Handle/services/auth_curd";
import { getPlayerMarketLimitList } from "../../../redux/services/valuebetmarketServices";

const Details = () => {
    const { slug } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [isLimitLoaded, setIsLimitLoaded] = useState(false);
  const { mobileScreen } = useSelector((state) => state.valuemarket);
  const { bets } = useSelector((state) => state.valuemarket.valueMarketDetails);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const state = searchParams.get("state") ? JSON.parse(decodeURIComponent(searchParams.get("state"))) : null;

  useEffect(() => {
      if(!isLimitLoaded) {
        let club_id = location?.state?.club?.id || state?.club?.id;
        dispatch(getPlayerMarketLimitList(club_id)).then(() => {
          setIsLimitLoaded(true);
        });
      }
      // eslint-disable-next-line
    }, []);

    const fetchMarketData = async () => {
      let response = await getValuebet(`${POLYMARKETS_LISTS}/${slug}`);
      if(response) setData(response);  
    }
  
    useEffect(() => {
    if (isLimitLoaded) fetchMarketData();
    // eslint-disable-next-line
  }, [isLimitLoaded]);

  return (
    <React.Fragment>
    <NavigationBar />
    <div className="market-container-fluid">
       <EventDetailsCard data={data} mobView={mobileScreen.step2}/>
      {<Bets mobView={mobileScreen.step3} openTab={"step2"} />}
      {mobileScreen.step2 && bets.length !== 0 && (
        <div className="bet-button-box position-absolute">
          <button
            className="bet-btn"
            onClick={() => dispatch(setMobileScreen("step3"))}
          >
            Bets ({bets.length})
          </button>
        </div>
      )}
    </div>
  </React.Fragment>
  );
};

export default Details;
