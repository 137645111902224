import React, { useEffect, useRef, useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styles } from "../../Css/styles";
import { useSelector, useDispatch } from "react-redux";
import { setSubCategory } from "../../../../redux/features/valuemarketSlice";

const SliderButtons = () => {
  const scrollContainerRef = useRef(null);
  const {subCategory, mainCategory, subCatList} = useSelector((state) => state.valuemarket);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const dispatch = useDispatch();

  const handleScroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -150 : 150;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const updateScrollButtonsState = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, offsetWidth } = container;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + offsetWidth < scrollWidth);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (container) {
      container.addEventListener("scroll", updateScrollButtonsState);
      updateScrollButtonsState();

      return () => {
        container.removeEventListener("scroll", updateScrollButtonsState);
      };
    }
  // eslint-disable-next-line
  }, [scrollContainerRef, subCatList]);

  useEffect(() => {
    if (subCategory) dispatch(setSubCategory(subCategory));
    else dispatch(setSubCategory(mainCategory));
  // eslint-disable-next-line
  }, [subCatList]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: 1, maxWidth: {
        xs: "calc(100vw - 16px)",
        lg:'100%',
        xl:'100%'
      } }}
    >
      <IconButton
        onClick={() => handleScroll("left")}
        disabled={!canScrollLeft}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <Box ref={scrollContainerRef} sx={styles.buttonScroll}>
        {(
          <Button
            variant="contained"
            onClick={() => dispatch(setSubCategory(mainCategory))} // Handle "all" click
            sx={styles.buttonStyles(subCategory, mainCategory)}
          >
            Top
          </Button>
        )}
        {subCatList?.data?.map((item, index) => (
          <Button
            key={index}
            variant="contained"
            onClick={() => dispatch(setSubCategory(item.slug))}
            sx={styles.menuButtonStyles(subCategory, item, subCategory)}
          >
            {item.label}
          </Button>
        ))}
      </Box>
      <IconButton
        onClick={() => handleScroll("right")}
        disabled={!canScrollRight}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default SliderButtons;
