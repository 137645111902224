import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import OddList from '../../../../Components/SportComp/OddList';
import { APP_OFFSET } from '../../../../constant';
import { getBetslipSubTitle } from '../../../../Utils/sportsbook';
import PolyOddList from '../../../../Components/PolyMarketComp/PolyOddList';
import SocialShareModal from '../../../../Components/PublicModals/SharePopup';
import { getAvailableWidth } from '../../../../Utils/comman';
import NavigateToMarkets from './BetActions/NavigateToMarkets';

function ExpandableView({ data, isModal }) {
  const location = useLocation();
  const [subTitle, setSubTitle] = useState('');

  const prepareData = () => {
    let sub_title = [];
    data.bets.forEach((bet_info) => {
      let { bet, match } = bet_info;
      let bet_name = getBetslipSubTitle(bet, match);
      sub_title.push(bet_name)
      bet.name = bet_name.split(" - ")[0];
    })
    setSubTitle(sub_title.join(", "))
  }

  useEffect(() => {
    prepareData()
    // eslint-disable-next-line
  }, [data])

  return (
    <>
      <div className='px-4 py-4 bet-history-container' style={{ width: getAvailableWidth(isModal) }}>
        <div className='mb-2'>
          {(data?.multibet_odd_money && data.status !== "PENDING" && data.bet_category === "VALUEMARKET") ?
            <div className="d-flex justify-content-between align-items-center w-100 mb-1">
              <div className="font-size-11px"> SETTLED: {moment.utc(data.updated_at).utcOffset(APP_OFFSET).format('DD/MM HH:mm') + " PST"}</div>
              <NavigateToMarkets data={data} />
            </div> :
            (data?.multibet_odd_money && data.bet_category === "VALUEMARKET") ?
              <div className="d-flex justify-content-start align-items-center w-100 mb-1">
                <NavigateToMarkets data={data} />
              </div> :
              ((data?.multibet_odd_money && data.status !== "PENDING") || (data.bet_category === "VALUEMARKET" && data.status !== "PENDING")) ?
                <div className="d-flex justify-content-start align-items-center w-100 mb-1">
                  <div className="font-size-11px"> SETTLED: {moment.utc(data.updated_at).utcOffset(APP_OFFSET).format('DD/MM HH:mm') + " PST"}</div>
                </div> : null}

          <div className='d-flex align-items-center w-100'>
            <div className='d-flex flex-column text-left w-100'>
              <div className="d-flex justify-content-between">
                <strong>{data.bet_type !== "Teaser" ? data.bet_type : `${data.teaser.points} Points ${data.teaser.is_super_teaser ? "Super" : ''} ${data.bet_type}`}</strong>
                {data?.multibet_odd_money ?
                  <strong className="text-nowrap text-success">
                    {data?.multibet_odd_money > 0
                      ? `+ ${data?.multibet_odd_money}`
                      : data?.multibet_odd_money}
                  </strong>
                  : data.bet_category === "VALUEMARKET" ?
                    <NavigateToMarkets data={data} />
                    : data.status !== "PENDING" ?
                      <div className="font-size-11px"> SETTLED: {moment.utc(data.updated_at).utcOffset(APP_OFFSET).format('DD/MM HH:mm') + " PST"}</div>
                      : null
                }
              </div>
              <span className="bet-subtitle-his mt-1">{subTitle}</span>
            </div>
          </div>
        </div>
        <div className='horizantal-line'></div>
        {data.bet_category === "VALUEMARKET" ? <PolyOddList data={data.bets} /> : <OddList data={data.bets} />}
        <div className='value-box-history py-2'>
          <div className='d-flex flex-column text-left'>
            <strong>{Number(data.risking).toFixed(2)} <small>Chips</small> </strong>
            <small className='bet-subtitle-text'>Total wager</small>
          </div>
          <div className='d-flex flex-column text-left'>
            {data.status === 'PENDING' ?
              <>
                <strong className='bet-subtitle-amount text-warning'>{(Number(data.risking) + Number(data.winnings)).toFixed(2)} <small>Chips</small></strong>
                <small className='bet-subtitle-text'>Payout if win (pending)</small>
              </>
              : null}
            {data.status === 'WON' ?
              <>
                <strong className='bet-subtitle-amount'>{Number(data.result).toFixed(2)} <small>Chips</small></strong>
                <small className='bet-subtitle-text'>{location?.state?.member ? 'Player' : 'You'} won on valuebet</small>
              </>
              : null}
            {data.status === 'LOSE' ?
              <>
                <strong className='bet-subtitle-amount text-danger'>{-(Number(data.result)).toFixed(2)} <small>Chips</small></strong>
                <small className='bet-subtitle-text'>{location?.state?.member ? 'Player' : 'You'} lose on valuebet</small>
              </>
              : null}
            {data.status === 'REFUNDED' ?
              <>
                <strong className='bet-subtitle-amount'>{Number(data.risking).toFixed(2)} <small>Chips</small></strong>
                <small className='bet-subtitle-text'>Chips refunded on valuebet</small>
              </>
              : null}
            {data.status === 'DELETED' ?
              <>
                <strong className='bet-subtitle-amount text-warning'>{Number(data.result).toFixed(2)} <small>Chips</small></strong>
                <small className='bet-subtitle-text'>BET DELETED</small>
              </>
              : null}
          </div>
        </div>
        <div className='bet-history-top-box py-2'>
          <div className='d-flex flex-column me-3'>
            <small className='bet-subtitle-text  text-nowrap'>Bet ID: {data.id}</small>
            <SocialShareModal data={data} />

          </div>
          <div className='d-flex flex-column'>
            <small className='bet-subtitle-text text-nowrap'>Placed: {moment.utc(data.created_at).utcOffset(APP_OFFSET).format('DD/MM HH:mm') + " PST"}</small>
            <small className='bet-subtitle-text mt-1'>Game/End: {moment.utc(data.event_time).utcOffset(APP_OFFSET).format('DD/MM HH:mm') + " PST"}</small>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExpandableView

