import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { styles } from "../Css/styles";
import MarketList from "../Layout/Common/MarketList";
import RulesSection from "./RuleSection";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { ArrowRight, LinkOutlined, SwapVert } from "@mui/icons-material";
import moment from "moment";
import { safeParseJSON, applyVigTax } from "../../../Utils/valuemakets";
import ToggleFilterButton from "../Layout/Common/ToggleFilterButton";
import MarketGraphStacked from "./StackGraph";
import MarketGraphSingle from "./GraphData";
import { useNavigate} from "react-router";
import { useSelector } from "react-redux";
import ValuebetContext from "../../../context/Valuebet/ValuebetContext";
import OrderLoader from "../../../Components/CustLoader/OrderLoader";

export default function EventDetailsCard({data,mobView}) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(null);
  const { activeClub } = useContext(ValuebetContext);
  const [sortOrder, setSortOrder] = useState("asc");
  const { toggleFilter } = useSelector((state) => state.valuemarket);
  const [initialThreshold, setInitialThreshold] = useState("0");
  const [top4ClobTokenIds, setTop4ClobTokenIds] = useState([]);

  const handleAccordionChange = (index) => {
    setExpanded(expanded === index ? null : index);
  };
  
  const toggleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    if (data?.markets) {
      const sortedTop4Ids = data?.markets
        .map((row) => {
          const formattedPrice = JSON.stringify([
            row.bestAsk.toString(),
            (1 - row.bestAsk - row.spread).toFixed(4),
          ]);
          return { ...row, formattedPrice };
        })
        .sort((a, b) => {
          const pricesA = safeParseJSON(a.formattedPrice) || [];
          const pricesB = safeParseJSON(b.formattedPrice) || [];
          const maxPriceA =
            pricesA.length > 0 ? Math.max(...pricesA.map(Number)) : 0;
          const maxPriceB =
            pricesB.length > 0 ? Math.max(...pricesB.map(Number)) : 0;
          return sortOrder === "asc"
            ? maxPriceA - maxPriceB
            : maxPriceB - maxPriceA;
        })
        .slice(0, 4) // Sorting ke baad top 4 records lo
        .map((row) => ({
          id: safeParseJSON(row.clobTokenIds)?.[0] || null,
          label: row.groupItemTitle || "",
        }));
      setTop4ClobTokenIds(sortedTop4Ids);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    
    <Grid
      mt="71px"
      ml="10px"
      pb="6px"
      sx={{
        ...styles.responsiveContainerStyles(toggleFilter, mobView),
        mr:'10px',
       
      }}
    >
      <Grid sx={{
      border: "0.1px solid #d52b00",
      borderRadius: "5px",
      mr: 1,
      mt:{
        xs: "10px",
      }
    }}>

      {!data ? <Box p={1}><OrderLoader /></Box> : 
      <Grid container sx={styles.detailsWrapper}>
        <Box
          sx={{
            ...styles.top,
            bgcolor: "#0b0b0b",
            color: "#fff",
            borderBottom: "1px solid #962c11",
            borderRadius: "10px 10px 0 0",
            py: 1,
            mb: "5px",
            px: {
              xs: 1,
            },
            width: "100%",
          }}
        >
          <Button
            onClick={() => navigate(-1)}
            sx={{
              ...styles.filterOption,
                minWidth: "40px",
                height: "40px",
                padding: 0,
                color: "#d52b00",
                '&:hover': {
                  backgroundColor: '#d52b00',
                }
            }}
          >
            <ArrowBackIcon fontSize="large" sx={{ color: "red" }} />
          </Button>
          <img
            src={data?.image}
            alt="event_logo"
            style={{
              maxWidth: "40px",
              maxHeight: "40px",
              minHeight: "40px",
              borderRadius: "50%",
              border: "1px solid #962c11",
              cursor: "pointer",
            }}
          />
          <Typography
            sx={{
              fontSize: {
                xl: "20px",
                lg: "18px",
                md: "18px",
                xs: "16px",
              },
              fontFamily: "inherit",
            }}
          >
            {data?.title}
          </Typography>
        </Box>
        <Box sx={styles.detailsHeader}>
          <Typography sx={styles.detailsTitle}>
            <CalendarMonthIcon fontSize="medium" />
            Est. End Date : {moment.utc(data?.endDate).format("MMM Do YYYY")}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} p={0}>
              <Card sx={{ background: "#161313" }}>
                <CardContent sx={{ p: 1, pb: "8px!important" }}>
                  <Box
                    sx={{
                      bgcolor: "#000",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 2,
                    }}
                  >
                    <MarketGraphStacked tokenIds={top4ClobTokenIds} />
                  </Box>
                </CardContent>
              </Card>
              <Button
                startIcon={<LinkOutlined />}
                sx={{
                  color: "#5ac3ff",
                  textTransform: "capitalize",
                  textDecoration: "underline",
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    xl: "end",
                    md: "end",
                  },
                  "&:hover": {
                    background: "transparent",
                    color: "#d52b00",
                  },
                }}
                component={Link}
                href={`https://polymarket.com/event/${data?.slug}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Polymarket Bet Page <ArrowRight style={{ fontSize: "30px" }} />
              </Button>
              <Box
                sx={{
                  my: 2,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <ToggleFilterButton />
                {/* Sort Toggle */}
                {data?.markets?.length !== 1 && (
                  <Button
                    onClick={toggleSort}
                    endIcon={<SwapVert />}
                    variant="contained"
                    sx={styles.filterOption}
                    size="small"
                  >
                    {sortOrder === "asc"
                      ? "Sort: Low to High"
                      : "Sort: High to Low"}
                  </Button>
                )}
              </Box>

              <Box>
                {data?.markets
                  ?.map((row) => {
                    // Format price correctly
                    const formattedPrice = applyVigTax(row, activeClub);
                    return { ...row, formattedPrice };
                  })
                  .sort((a, b) => {
                    // Parse formattedPrice correctly before sorting
                    const pricesA = safeParseJSON(a.formattedPrice) || [];
                    const pricesB = safeParseJSON(b.formattedPrice) || [];
                    const maxPriceA =
                      pricesA.length > 0 ? Math.max(...pricesA.map(Number)) : 0;
                    const maxPriceB =
                      pricesB.length > 0 ? Math.max(...pricesB.map(Number)) : 0;
                    return sortOrder === "asc"
                      ? maxPriceA - maxPriceB
                      : maxPriceB - maxPriceA;
                  })
                  .filter((row) => row.active && !row.closed)
                  .map((row, index) => (
                    <Card
                      key={row.id || index}
                      sx={{
                        background:
                          "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
                        mt: index === 0 ? 0 : 1,
                      }}
                    >
                      <CardContent
                        sx={{
                          pb: "0rem!important",
                          pt: "0rem!important",
                          px: 0,
                        }}
                      >
                        <Accordion expanded={expanded === index}>
                          <AccordionSummary
                            sx={styles.summaryTitle}
                            onClick={() =>
                              setInitialThreshold(row.groupItemThreshold)
                            }
                          >
                            <MarketList
                              key={row.id || index}
                              image={row.image}
                              marketId={row.id}
                              eventId={data?.id}
                              isSingle={data?.markets?.length === 1}
                              name={row.groupItemTitle}
                              eventName={data?.title}
                              outcome={row.outcomes}
                              token={row.clobTokenIds}
                              price={row.formattedPrice}
                              uiType={"OUTSIDE"}
                              onTitleClick={() => handleAccordionChange(index)}
                            />
                          </AccordionSummary>
                          <AccordionDetails sx={{ maxWidth: "100%", px: 0 }}>
                            {expanded === index && (
                              <MarketGraphSingle
                                marketId={safeParseJSON(row.clobTokenIds)}
                              />
                            )}{" "}
                          </AccordionDetails>
                        </Accordion>
                      </CardContent>
                    </Card>
                  ))}
              </Box>
            </Grid>
          </Grid>
          <RulesSection data={data} initialThreshold={initialThreshold} />
        </Box>
      </Grid>}
    </Grid>
    </Grid>
  );
}
