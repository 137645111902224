import React, { useEffect, useState } from 'react'
import SmSpinner from '../../../../../../Components/CustLoader/SmSpinner';
import { GET_CLUB_POLYBET_ODD_LIMITS_URL, getValuebet, postValuebet, UPDATE_POLYBET_ODD_LIMIT_URL } from '../../../../../../API-Handle/services/auth_curd';
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert';

const MarketOddLimits = ({ club, toggleClubSetting }) => {
    const [oddLimits, setOddLimits] = useState({
        neg_odd_limits: '',
        pos_odd_limits: ''
    });
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        const fetchLimits = async () => {
            const res = await getValuebet(GET_CLUB_POLYBET_ODD_LIMITS_URL + club.id)
            if (res) {
                setOddLimits(res)
            }
        }
        fetchLimits()
    }, [])

    const updateVal = (value, type) => {
        setOddLimits(prev => ({
            ...prev,
            [type === "NEG" ? "neg_odd_limits" : "pos_odd_limits"]: value
        }));
    };



    const handleSave = async () => {
        setLoader(true);
        try {
            let res = await postValuebet(UPDATE_POLYBET_ODD_LIMIT_URL, {
                club_id: club.id,
                market_odd_limits: oddLimits
            });
            if (res) {
                successNotification(res.message);
                toggleClubSetting();
            }
        } catch (error) {
            console.error('Error saving odd limits:', error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <>
            <div className="row g-3 pt-2">
                <div className="col-12 col-sm-6">
                    <div className="mb-2 text-nowrap text-white">
                        Hide (-) Odds
                    </div>
                    <div className="input-group input-group-sm w-100">
                        <span className="input-group-text bg-secondary text-white" id={`neg`}>-</span>
                        <input
                            type="text"
                            value={oddLimits.neg_odd_limits}
                            onChange={(e) => updateVal(e.target.value, "NEG")}
                            className="form-control bg-dark text-white"
                            placeholder="Value"
                            aria-describedby={`neg`}
                        />
                    </div>
                </div>
                <div className="col-12 col-sm-6">
                    <div className="mb-2 text-white">
                        Hide (+) Odds
                    </div>
                    <div className="input-group input-group-sm w-100">
                        <span className="input-group-text bg-secondary text-white" id={`pos`}>+</span>
                        <input
                            type="text"
                            value={oddLimits.pos_odd_limits}
                            onChange={(e) => updateVal(e.target.value, "POS")}
                            className="form-control bg-dark text-white"
                            placeholder="Value"
                            aria-describedby={`pos`}
                        />
                    </div>
                </div>
            </div>
            <div className="text-end mt-4">
                {loader ?
                    <button type="button" className="btn btn-danger btn-orange btn-sm">
                        <SmSpinner inline={true} />
                    </button>
                    :
                    <button
                        type="button"
                        className="btn btn-danger btn-orange btn-sm"
                        onClick={handleSave}
                    >
                        Save changes
                    </button>
                }
                &nbsp;
                <button
                    onClick={toggleClubSetting}
                    type="button"
                    className="btn btn-secondary btn-sm"
                >
                    Cancel
                </button>
            </div>
        </>
    )
}

export default MarketOddLimits