import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import ExpandableView from '../ExpandableView';
import moment from 'moment'
import { CLUB_DETAILED_BET_HISTORY } from '../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom'
import { APP_OFFSET } from '../../../../../constant';
import Spinner from '../../../../../Components/CustLoader/Spinner';
import DeleteBet from '../../../Reports/Bets/BetActions/DeleteBet';
import { getBetslipTitle } from '../../../../../Utils/sportsbook';

export default function ExpView({ data }) {
    const [preparedData, setPreparedData] = useState(null)
    const location = useLocation()

    const columns = [
        {
            name: 'Participant',
            selector: row => row.participant,
            sortable: true,
            minWidth: "240px"
        },
        {
            name: 'Risk',
            selector: row => row.risking.toFixed(2),
            sortable: true
        },
        {
            name: 'To Win',
            selector: row => row.winnings.toFixed(2),
            sortable: true
        },
        {
            name: 'Action',
            cell: row => <DeleteBet data={row} getBets={getBets}/>,
            sortable: true,
            width: "100px"
        }
    ];
    
    const getBets = async () => {
        setPreparedData(null)
        let club_id = location?.state?.club?.id;
        if (!club_id) return;

        let request_url = CLUB_DETAILED_BET_HISTORY + club_id + `?bet_type=UNSETTLED&player_id=${data.user_id}`;
        let bets = await getValuebet(request_url);
        prepareData(bets);
    }

    const prepareData = async (bets) => {
        if (bets) {
            for (let row of bets) {
                row.participant = getBetslipTitle(row);
                row.final_date = moment.utc(row.created_at).utcOffset(APP_OFFSET).format('L LT') + " PST"
            }
            setPreparedData(bets)
        }
    }

    useEffect(() => {
        getBets()
        // eslint-disable-next-line
    }, [])

    return (
        <div className='px-4 py-2 head-yellow responsive-expand'>
            {preparedData ?
                <DataTable
                    columns={columns}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpandableView />}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    footer={undefined}
                /> :
                <Spinner />}
        </div>
    )
}
