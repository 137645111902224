import React, { useEffect, useState } from "react";
import FullLogo from "../../../Static/UserManagement/images/common/vb_logo_white.png";
import { Typography } from "@mui/material";
import {
  GET_SINGLE_BETSLIP,
  getValuebet,
} from "../../../API-Handle/services/auth_curd";
import { useParams } from "react-router";
import Spinner from "../../../Components/CustLoader/Spinner";
import ExpandableView from "../../Sportbook/ClubReports/Bets/ExpandableView";

export default function BetWithPayment() {
  const [betData, setBetData] = useState(null);
  const param = useParams();

  const getSingleBetSlip = async () => {
    let res = await getValuebet(GET_SINGLE_BETSLIP + param?.betslip_id);
    if (res) setBetData(res);
  };

  useEffect(() => {
    getSingleBetSlip();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="align-items-center mt-xl-5 my-3  container-sm d-flex flex-column mx-auto   rounded-lg text-white">
      <div className="bg-gray-800 p-4 bet-container rounded-lg shadow-md text-center w-100">
        <div className="flex justify-center items-center mb-2">
          <div className="bg-yellow-500 p-2 rounded-full">
            <img src={FullLogo} alt="Valuebet" className="full-logo-xl" />
          </div>
        </div>
        <Typography
          sx={{
            mb: 1,
            fontSize: {
              xl: "27px",
              lg: "27px",
              md: "25px",
              sm: "25px",
              xs: "24px",
            },
          }}
        >
          Sports Betting Management Platform
        </Typography>
        <p className="text-sm text-gray-400">
          Start Your Own Social Gaming & Predictions Market NOW
        </p>
      </div>
      {betData ? (
        <ExpandableView
          data={betData}
          isOuter={true}
        />
      ) : (
        <Spinner py={5} />
      )}
    </div>
  );
}
