import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logoSm from '../../Static/UserManagement/images/common/logov.png';
import ProfileMenu from './profileMenu';
import NotiMenu from '../CustComp/NotiMenu';
import ValuebetContext from '../../context/Valuebet/ValuebetContext';
import { useDispatch, useSelector } from 'react-redux';
import ClubMessages from '../ClubMessages';
import { clearAllData } from '../../redux/services/valuebetmarketServices';

const pure_back_paths = ['/players-report', '/agent-players', '/agent-report'];

const Index = () => {
    const { userDetails, resetSportBook, activeClub } = useContext(ValuebetContext);
    const location = useLocation();
    const dispatch = useDispatch()
    const { isClubChatOpen } = useSelector((state) => state.clubChat);

    useEffect(() => {
        resetSportBook();
        dispatch(clearAllData())
    // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            {isClubChatOpen && <ClubMessages />}
            <header id="topnav" className='container-fluid'>
                <div className="topbar-main">
                    <div className="logo">
                        {location.pathname !== "/dashboard" &&
                            <>
                                {pure_back_paths.includes(location.pathname) ?
                                    <span role="button" onClick={() => { window.history.go(-1) }} className='me-3 back-button'>
                                        <i className="fas fa-angle-left text-white back-button-size"></i>
                                    </span> :
                                    <Link to="/dashboard" className='me-3 back-button'>
                                        <i className="fas fa-angle-left text-white back-button-size"></i>
                                    </Link>}
                            </>}
                        <Link to="/dashboard">
                            <img src={logoSm} alt="" className="logo-small" />
                            <img src={logoSm} alt="" className="logo-large" />
                            {/* <span className={`beta-icon text-warning ${location.pathname !== "/dashboard" ? "beta-icon-110-left" : ""}`}>Beta</span> */}
                        </Link>
                        {location.pathname !== "/dashboard" ?
                            // <span className='club-title'>{activeClub?.name?.toLocaleUpperCase()}</span> 
                            activeClub?.name && <span className='club-title'>{(window.innerWidth < 410 && activeClub.name.length > 13) ? `${activeClub.name.substring(0, 10).toLocaleUpperCase()}...` : activeClub.name.toLocaleUpperCase()}</span>
                            : <h6 className="sec-header-title hello-title">
                                Hello, {userDetails?.username ? userDetails.username.length > 12 ? `${userDetails.username.substring(0, 9)}...` : userDetails.username : "user"}
                            </h6>}
                    </div>
                    <div className="menu-extras topbar-custom">
                        <ul className="list-inline float-end mt-2 d-flex align-items-center">
                            <NotiMenu />
                            <ProfileMenu />
                        </ul>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Index;
