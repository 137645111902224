import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../Css/styles";
import { IconButton } from "@mui/material";
import { PostFavEvent } from "../../../../redux/services/valuebetmarketServices";
import { useContext } from "react";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";

const ToggleFav = ({eventId}) => {
  const dispatch = useDispatch();
  const { favEvents } = useSelector((state) => state.valuemarket);
  const isFav = favEvents.some((fav) => fav === eventId);
  const {setRootLoader} = useContext(ValuebetContext)

  const handleFavAction = async () => {
    setRootLoader(true)
 await dispatch(PostFavEvent(eventId))
 setRootLoader(false)
  }
  return (
    <IconButton sx={{...styles.iconRyt,p:'0px 2px'}} onClick={() => handleFavAction()}>
      {isFav ? (
        <StarIcon sx={{color:"#e03f00"}}   />
      ) : (
        <StarBorderIcon  />
      )}
    </IconButton>
  );
};

export default ToggleFav;
