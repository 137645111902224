import React, { useContext, useEffect } from "react";
import ValuebetContext from "../../../../../context/Valuebet/ValuebetContext";
import {
  postValuebet,
  STRAIGHT_POLY_PLACE_BET,
} from "../../../../../API-Handle/services/auth_curd";
import {
  errorNotification,
  successNotification,
} from "../../../../../Components/CustComp/PPHAlert";
import {
  clearBets,
  updateBets,
} from "../../../../../redux/features/valuemarketSlice";
import { useLocation } from "react-router-dom";
import { OFFLINE_ODD } from "../../../../../constant";
import { WinToRisk } from "../../../../../Utils/sportsbook";
import { useDispatch } from "react-redux";
import DelayedTimer from "../../../../../Components/CustComp/DelayedTimer";
import Checkbox from "../../../../Sportbook/Sports/Bets/BetComman/Checkbox";
import BetConfirmation from "../../../../Sportbook/Sports/Bets/BetComman/BetConfirmation";

function BetBottom({ details,market_bet_limits }) {
  const dispatch = useDispatch();
  const timeoutRef = React.useRef(null);
  const {min_bet_all,max_bet_all} = market_bet_limits
  const [isSelect, setisSelect] = React.useState(false);
  const [isConfirm, setIsConfirm] = React.useState(true);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [resData, setResData] = React.useState(false);
  const location = useLocation();
  const [riskTotal, setRiskTotal] = React.useState(0)
  const [winTotal, setWinTotal] = React.useState(0)
  const {
    rootLoader,
    setRootLoader,
    fetchSingleClub,
    activeClub,
  } = useContext(ValuebetContext);

  useEffect(() => {
    let risk_total = 0;
    let win_total = 0;
    let is_confirm = false;
    for (let bet of details.bets) {
      if (bet.risking) risk_total += bet.risking;
      if (bet.to_win) win_total += bet.to_win;
      if (!is_confirm) {
        if (
          bet.risking !== bet.init_risk ||
          bet.to_win !== bet.init_win ||
          bet.sub_title !== bet.init_sub_title
        ) {
          is_confirm = true;
        }
      }
    }
    setRiskTotal(risk_total)
    setWinTotal(win_total)
    setIsConfirm(is_confirm);
  }, [details]);

  useEffect(() => {
    if (isConfirm && !isSelect && timeoutRef) {
      clearTimeout(timeoutRef.current);
      setRootLoader(false);
    }
    // eslint-disable-next-line
  }, [isConfirm]);

  const acceptChanges = async () => {
    let bets = JSON.parse(JSON.stringify(details.bets));
    for (let bet of bets) {
      bet.init_sub_title = bet.sub_title;
      if (bet?.risking !== undefined || bet?.risking !== null)
        bet.init_risk = bet.risking;
      if (bet?.to_win !== undefined || bet?.to_win !== null)
        bet.init_win = bet.to_win;
    }
    dispatch(updateBets(bets));
    placeBet();
  };

  const validateBet = (bet) => {
    if (!bet?.risking) {
      return "Please enter a valid risking value!";
    }

    if (bet.avg_price === OFFLINE_ODD) {
      return "Please remove the ticket which is no longer available.";
    } else if (bet.avg_price > 0) {
      if (bet.risking < min_bet_all || bet.risking > max_bet_all) {
        return `Risking value must be greater than or equal to ${min_bet_all} and less than or equal to ${max_bet_all}.`;
      }
    } else {
      let risk_max_bet_all = WinToRisk(bet.avg_price, max_bet_all);
      risk_max_bet_all = Number(risk_max_bet_all.toFixed(2));
      if (bet.risking < min_bet_all || bet.risking > risk_max_bet_all) {
        return `Risking value must be greater than or equal to ${min_bet_all} and less than or equal to ${risk_max_bet_all}.`;
      }
    }

    return null; // No validation errors
  };

  const buildOddPayload = (bet) => {
    const oddData = {
      odd_id: bet.odd_id,
      risking: bet.risking,
      to_win: bet.to_win,
      avg_price: bet.avg_price_actual,
      event_id: bet.event_id,
      market_id: bet.market_id,
      odd_points: bet.odd_points,
    };

    if (bet?.proposition) {
      Object.assign(oddData, {
        proposition: bet.proposition,
        event_id: bet.event_id,
        sportsbook_id: bet.sportsbook_id,
        market_id: bet.market_id,
        participant_id: bet.participant_id,
        participant_type: bet.participant_type,
        period_id: bet.period_id,
      });
    }

    return oddData;
  };

  const placeBet = async () => {
    if (details.bets.length < 7) {
      setRootLoader(true);

      // it's just for raise immediate error
      for (let bet of details.bets) {
        const validationError = validateBet(bet);
        if (validationError) {
          errorNotification(validationError);
          setRootLoader(false);
          return;
        }
      }

      timeoutRef.current = setTimeout(
        async () => {
          // it will recheck errors after timeout and build payloads;
          for (let bet of details.bets) {
            const validationError = validateBet(bet);
            if (validationError) {
              errorNotification(validationError);
              setRootLoader(false);
              return;
            }
          }

          let payloads = {
            auto_accept: isSelect,
            bet_type: details.bet_info.bet_type,
            club_id: location?.state?.club?.id,
            odds: details.bets.map(buildOddPayload),
          };
         
          let res = await postValuebet(STRAIGHT_POLY_PLACE_BET, payloads);
          if (res?.message) {
            successNotification(res.message);
            setResData(res);
            setIsSuccess(true);
            fetchSingleClub(location?.state?.club?.id);
            window.gtag("event", "conversion", {
              send_to: "AW-16724820378/U3L_CLChgN0ZEJqDgqc-",
            });
          }
          setRootLoader(false);
        },
        activeClub?.bet_delay_toggle ? activeClub?.bet_delay_seconds * 1000 : 0
      );
    }
  };

  const removeAllBets = () => {
    dispatch(clearBets())
  }

  const toggleOnBetSuccess = () => {
    removeAllBets();
    setIsSuccess(false);
  };

  const timeSleep = (ms) => {
    setTimeout(function (buttons) {}, ms);
    return true;
  };

  const maxAllBets = async () => {
    let buttons = document.getElementsByClassName("straigth-max");
    for (var i = 0; i < buttons.length; i++) {
      await timeSleep(100);
      buttons[i].click();
    }
  };

  return (
    <>
      <BetConfirmation
        isActive={isSuccess}
        toggleHandler={toggleOnBetSuccess}
        data={resData}
      />
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-sm bg-warning max-all-bet mb-3"
          onClick={maxAllBets}
        >
          Max All
        </button>
      </div>
      <div className="bet-bottom">
        <p className="bet-bottom-text">
          Risking {Number(riskTotal).toFixed(2)}{" "}
          <small className="font-size-10px">Chips</small>
        </p>
        <p className="bet-bottom-text">
          To Win {Number(winTotal).toFixed(2)}{" "}
          <small className="font-size-10px">Chips</small>
        </p>
        <div>
          {isConfirm && !isSelect && (
            <div className="text-warning mb-2">
              Odds have changed. Accept new Odds?
            </div>
          )}
          <Checkbox
            label={"Auto Accept Changes"}
            select={isSelect}
            onChange={() => setisSelect(!isSelect)}
          />
          {isConfirm && !isSelect ? (
            <div className="mb-2">
              <button
                className="btn btn-secondary btn-sm w-40"
                onClick={removeAllBets}
              >
                Cancel
              </button>
              <button
                className="btn btn-success btn-sm w-40 ms-2"
                onClick={acceptChanges}
              >
                Confirm
              </button>
            </div>
          ) : location?.state?.club?.label ? (
            <button className="bet-place-btn" 
            onClick={placeBet}
            >
              {rootLoader && activeClub?.bet_delay_toggle ? (
                <span>
                  Placing in{" "}
                  <DelayedTimer timer={activeClub?.bet_delay_seconds} /> sec.{" "}
                </span>
              ) : (
                "Place Bet"
              )}
            </button>
          ) : (
            <div className="mb-2">
              <button
                className="btn btn-sm w-40 btn-orange text-white"
                onClick={() => document.getElementById("signup_1").click()}
              >
                Signup
              </button>
              <button
                className="btn btn-sm w-40 btn-orange text-white ms-2"
                onClick={() => document.getElementById("login_1").click()}
              >
                Login
              </button>
            </div>
          )}
          <button className="remove-selection-btn" 
          onClick={removeAllBets}
          >
            Remove selections
          </button>
        </div>
      </div>
    </>
  );
}

export default BetBottom;
