import React, { useState } from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { styles } from "../Css/styles";

const RulesSection = ({ data,initialThreshold }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const filteredItems =
    data?.markets?.filter(
      (item) => item.groupItemThreshold === initialThreshold
    ) || [];

  return (
    <Card sx={styles.ruleWrapper}>
       <CardContent>
        <Typography variant="h6" gutterBottom>
          Rules
        </Typography>

        {filteredItems.length > 0 && (
          <>
            {filteredItems.map((item, index) => (
              <Typography key={index} mt={2}>
                {expanded
                  ? item.description
                  : `${item.description.split("\n")[0]}...`}
              </Typography>
            ))}

            <Button
              onClick={toggleExpanded}
              variant="contained"
              sx={{ ...styles.filterOption, mt: 2 }}
              size="small"
            >
              {expanded ? "Show less ▲" : "Show more ▼"}
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default RulesSection;
