import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import ExpandableView from '../../../../../../../Sportbook/Reports/Bets/ExpandableView';
import moment from 'moment'
import { PLAYER_BET_HISTORY } from '../../../../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../../../../API-Handle/services/auth_curd';
import { APP_OFFSET } from '../../../../../../../../constant';
import CustomWeekPicker from '../../../../../../../../Components/Calendar/CustomWeekPicker';
import Spinner from '../../../../../../../../Components/CustLoader/Spinner';
import { getBetslipTitle } from '../../../../../../../../Utils/sportsbook';

const conditionalRowStyles = [
    {
        when: row => row.status === "WON",
        style: {
            backgroundColor: 'rgb(82 167 123 / 90%)',
            color: 'white',
        },
    },
    {
        when: row => row.status === "LOSE",
        style: {
            backgroundColor: 'rgb(255 94 79 / 48%)',
            color: 'white',
            // '&:hover': {
            // 	cursor: 'not-allowed',
            // },
        },
    },
    {
        when: row => row.status === "REFUNDED",
        style: {
            backgroundColor: '#193438',
            color: 'white',
        },
    },
    {
        when: row => row.status === "TOTAL",
        style: {
            backgroundColor: '#ffb100',
            marginBottom: "5px",
            color: '#000000',
        },
    },
];

const columns = [
    {
        name: 'Participant',
        selector: row => row.status === "TOTAL" ? <b>{row.participant}</b> : row.participant,
        minWidth: "240px"
    },
    {
        name: 'Risk',
        selector: row => row.risking,
    },
    {
        name: 'Results',
        selector: row => row.result,
    },
    // {
    //     name: 'Vig Chips Burned',
    //     selector: row => row.vig_paid,
    // },
    // {
    //     name: 'Kickbacks',
    //     selector: row => row.kickback,
    // },
    // {
    //     name: 'NET',
    //     selector: row => row.net,
    // },
];

export default function MemberSettled({ club, item }) {
    const [preparedData, setPreparedData] = useState(null)
    const [week, setWeek] = useState(null);
    const [dynmcolmn, setDynmcolmn] = useState([]);

    const getBets = async () => {
        setPreparedData(null)
        if (!club) return;
        setDynmcolmn(columns);
        let club_id = club.id;
        let request_url = PLAYER_BET_HISTORY + club_id + `?bet_type=SETTLED&player_id=${item?.id}`;
        if (week) {
            request_url += `&report_time=${week}`;
        }
        let bets = await getValuebet(request_url);
        if (club.is_special) {
            bets.sort((a, b) => Date.parse(a.event_time) - Date.parse(b.event_time)) // TODO: backend sort
        } else {
            bets.sort((a, b) => Date.parse(a.updated_at) - Date.parse(b.updated_at)) // TODO: backend sort
        }
        prepareData(bets);
    }

    const prepareData = async (data) => {
            if (!data) return;
        
            let totals = data.reduce((acc, row) => {
                row.participant = getBetslipTitle(row);
                acc.risking += row.risking;
                acc.result += row.result;
                // row.net = row.kickback + row.result;        
                return acc;
            }, { risking: 0, result: 0});
        
            setDynmcolmn(prevCols => prevCols.map(col => ({
                ...col,
                name: col.name.startsWith('Risk') ? `Risk (${totals.risking.toFixed(2)})` :
                      col.name.startsWith('Results') ? `Results (${totals.result.toFixed(2)})` :
                      col.name
            })));
        
            groupByDay(data);
        };

    const groupByDay = async (data) => {
        let total_for_day = null
        let prev_date = null
        let collections = []
        for (let row of data) {
            let date;
            if (club.is_special) {
                date = moment.utc(row.event_time).utcOffset(APP_OFFSET).format('L');
            } else {
                date = moment.utc(row.updated_at).utcOffset(APP_OFFSET).format('L');
            }
            if (date === prev_date) {
                total_for_day['risking'] += row.risking
                total_for_day['result'] += row.result
                // total_for_day['kickback'] += row.kickback
                // total_for_day['vig_paid'] += row.vig_paid
                // total_for_day['net'] += row.net
            } else {
                if (total_for_day) {
                    collections.push(total_for_day)
                }
                total_for_day = {
                    id: `TOTAL${date}`,
                    status: 'TOTAL',
                    participant: `Total for Day (${date})`,
                    risking: row.risking,
                    result: row.result,
                    // kickback: row.kickback,
                    // vig_paid: row.vig_paid,
                    // net: row.net,
                }
                prev_date = date;
            }
            collections.push(row)
        }
        if (total_for_day) {
            collections.push(total_for_day)
        }

        // Fixed decimal number 
        for (let one_coll of collections) {
            one_coll['risking'] = one_coll['risking'].toFixed(2)
            one_coll['result'] = one_coll['result'].toFixed(2)
            // one_coll['kickback'] = one_coll['kickback'].toFixed(2)
            // one_coll['vig_paid'] = one_coll['vig_paid'].toFixed(2)
            // one_coll['net'] = one_coll['net'].toFixed(2)
        }
        setPreparedData(collections)
    }

    useEffect(() => {
        if (week) getBets();
        // eslint-disable-next-line
    }, [week])

    return (
        <>
            <div className='d-flex justify-content-end mb-2'>
                <CustomWeekPicker setWeek={setWeek} className={"settled-datepicker"} position={"bottom right"} />
            </div>
            {preparedData ?
                <DataTable
                    columns={dynmcolmn}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpandableView isModal={true} />}
                    expandableRowDisabled={(row) => (row.status === 'TOTAL')}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    conditionalRowStyles={conditionalRowStyles}
                    footer={null}
                // noTableHead={index}
                /> :
                <Spinner />}
        </>
    )
}
