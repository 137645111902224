import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { CLUB_BET_HISTORY } from '../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom'
import CustomWeekPicker from '../../../../../Components/Calendar/CustomWeekPicker';
import Spinner from '../../../../../Components/CustLoader/Spinner';
import ExpView from './ExpView';

const conditionalRowStyles = [
    {
        when: row => row?.status === "TOTAL",
        style: {
            backgroundColor: '#ffb100',
            marginBottom: "5px",
            color: '#000000',
        },
    },
];

const columns = [
    {
        name: 'Player',
        selector: row => row.status === "TOTAL" ? <b>{row.username}</b> : row.username,
        minWidth: "200px"
    },
    {
        name: 'Risk',
        selector: row => row.risking,
    },
    {
        name: 'Player Results',
        selector: row => row.result,
    },
    {
        name: 'Date',
        selector: row => row.date,
        minWidth: "200px"
    }
];

export default function Index() {
    const [preparedData, setPreparedData] = useState(null)
    const [dynmcolmn, setDynmcolmn] = useState(columns);
    const [week, setWeek] = useState(null);
    const location = useLocation()

    const getBets = async () => {
        setPreparedData(null)
        
        let club = location?.state?.club;
        if (!club) return;

        let club_id = club?.id;        
        let request_url = CLUB_BET_HISTORY + club_id + "?bet_type=SETTLED";
        if (week) {
            request_url += `&report_time=${week}`;
        }
        let bets = await getValuebet(request_url);
        groupByDay(bets);
    }

    const groupByDay = (data) => {
        let totalForWeek = { risking: 0, result: 0 };
        let collections = [];
    
        data.forEach(groups => {
            let users = Object.values(groups)[0] || [];
            let date = Object.keys(groups)[0];
    
            let totalForDay = { id: `TOTAL${date}`, status: 'TOTAL', username: `Total for Day (${date})`, risking: 0, result: 0, date: "-" };
    
            users.forEach(user => {
                collections.push(user);
                totalForDay.risking += user.risking;
                totalForDay.result += user.result;
            });
    
            collections.push(totalForDay);
            totalForWeek.risking += totalForDay.risking;
            totalForWeek.result += totalForDay.result;
        });
    
        totalForWeek.risking = totalForWeek.risking.toFixed(2);
        totalForWeek.result = totalForWeek.result.toFixed(2);
    
        setDynmcolmn(prevCols =>
            prevCols.map(col => {
                if (col.name.startsWith("Risk")) {
                    return { ...col, name: `Risk (${totalForWeek.risking})` };
                }
                if (col.name.startsWith("Player Results")) {
                    return { ...col, name: `Player Results (${totalForWeek.result})` };
                }
                return col;
            })
        );
    
        setPreparedData(collections);
    };
    

    useEffect(() => {
        if (week) getBets();
        // eslint-disable-next-line
    }, [week])

    return (
        <>
            <div className='inline-datepicker-container'>
                <CustomWeekPicker setWeek={setWeek} className={"settled-datepicker"} position={"bottom right"}/>
            </div>
            {preparedData ?
                <DataTable
                    columns={dynmcolmn}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpView />}
                    expandableRowDisabled={(row) => (row.status === 'TOTAL')}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    conditionalRowStyles={conditionalRowStyles}
                    footer={null}
                // noTableHead={index}
                /> :
                <Spinner />}
        </>
    )
}
