import React from 'react';
import { Col, Row } from 'reactstrap';
import { postValuebet, REGISTER_URL } from '../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../Components/CustComp/PPHAlert'
import { Modal } from 'reactstrap';
import { beforeSignup, manageAllInputsErrors, refineUsername } from '../../../Utils/comman';
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import SmSpinner from '../../../Components/CustLoader/SmSpinner';

class Register extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      input_errors:
      {
        username: '',
        email: '',
        password: '',
        referral: "",
      },
      username: "", email: "", password: "", referral: "",
      is_field_tracker_on: false,
      loading: false,
      isVisible: false,
    }
  }

  static contextType = ValuebetContext;

  componentDidMount() {
    // Fetch latest referral_id when component mounts
    const params = new URLSearchParams(window.location.search);
    const referralId = params.get('referral_id');
    
    if (referralId) {
      localStorage.setItem('referral_id', referralId);
      this.setState({ referral: referralId });
      document.getElementById("signup_1").click();
    } else {
      const storedReferralId = localStorage.getItem("referral_id");
      if (storedReferralId) {
        this.setState({ referral: storedReferralId });
      }
    }
  }

  handleChange = (e) => {
    if (['username', 'email'].includes(e.target.name)) {
      e.target.value = refineUsername(e.target.value)
    }
    if (this.state.is_field_tracker_on) {
      const { username_error, email_error, password_error, referral_error } = manageAllInputsErrors(e);
      this.setState({
        input_errors: {
          username: username_error,
          email: email_error,
          password: password_error,
          referral: referral_error
        }
      })
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = async () => {
    this.setState({ loading: true })
    const { username_error, email_error, password_error, referral_error } = beforeSignup(this.state.username, this.state.email, this.state.password, this.state.referral);
    this.setState({ input_errors: { username: username_error, email: email_error, password: password_error, referral: referral_error } })
    if (username_error === '' && password_error === '' && email_error === '' && referral_error === '') {
      let data = { username: this.state.username, email: this.state.email, password: this.state.password};
      if (this.state.referral.length !== 0) data['referral_id'] = this.state.referral;
      let res = await postValuebet(REGISTER_URL, data, true);
      if (res) {
        this.setState({
          username: "",
          email: "",
          password: "",
          referral: ""
        })
        successNotification(res.message)
        window.gtag('event', 'conversion', {'send_to': 'AW-16724820378/7fdJCNiYgd0ZEJqDgqc-'});
        localStorage.removeItem('referral_id');
        this.openLogin()
      }
    } else {
      this.setState({ is_field_tracker_on: true });
    }
    this.setState({ loading: false })
  }

  toggle = () => {
    this.setState({ isVisible: !this.state.isVisible });
  }

  openLogin = () => {
    this.toggle()
    document.getElementById("login_1").click()
  }

  render() {
    const { loading, isVisible } = this.state;
    return (
      <React.Fragment>
        <Modal isOpen={isVisible} className="modal-dialog-centered modal-md">
          <div className="modal-header bg-vb-dark border-bottom-0">
            <button onClick={this.toggle} type="button" className="text-white btn-close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className='pb-4'>
            <div className="overflow-hidden account-card mx-2">
              <div className="text-white text-center position-relative">
                <h5 >Free Register !</h5>
                <p> Get your free account now.</p>
              </div>
              <div className="px-5 pb-4 pt-3">
                <form className='formcntr'>
                  <div>
                    <label className='text-light font-size-14px' htmlFor="username">Username</label>
                    <input className='form-control bg-dark text-white border-secondary' type="text" name="username"
                      onChange={this.handleChange}
                      value={this.state.username} id="username" placeholder="" />
                  </div>
                  {this.state.input_errors.username ? <div className='inputerrors'> {this.state.input_errors.username} </div> : null}
                  <div className="mt-3">
                    <label className='text-light font-size-14px' htmlFor="email">Email</label>
                    <input className='form-control bg-dark text-white border-secondary' type="text" name="email"
                      onChange={this.handleChange}
                      value={this.state.email} id="email" placeholder="" />
                  </div>
                  {this.state.input_errors.email ? <div className='inputerrors'>
                    {this.state.input_errors.email} </div> : null}
                  <div className="mt-3">
                    <label className='text-light font-size-14px' htmlFor="password">Password</label>
                    <input className='form-control bg-dark text-white border-secondary' type="password" name="password" id="password"
                      onChange={this.handleChange} value={this.state.password} placeholder="" />
                  </div>
                  {this.state.input_errors.password ? <div className='inputerrors'> {this.state.input_errors.password} </div> : null}
                  <div className="mt-3">
                    <label className='text-light font-size-14px' htmlFor="referral">Referral's User ID (optional)</label>
                    <input className='form-control bg-dark text-white border-secondary' type="text" name="referral" id="referral"
                      onChange={this.handleChange} value={this.state.referral} placeholder="" />
                  </div>
                  {this.state.input_errors.referral ? <div className='inputerrors'> {this.state.input_errors.referral} </div> : null}
                  <Row>
                    <Col className="text-center">
                      <button className='signbtn'
                        onClick={this.handleSubmit} type="button" disabled={loading}> {loading ? <SmSpinner inline={true} /> : "Register"} <i className="fa fa-arrow-right submticon" aria-hidden="true"></i>  </button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
            <div className="text-center text-white">
              <p>By registering you agree to the <span className='clryllo' role='button'> Terms of use </span> </p>
              <div className='mt-2'>Already have an account ?  <span className={"font-500 clryllo"} role='button' onClick={this.openLogin}> Login </span> </div>
            </div>
          </div>
        </Modal>
        <span className={this.props.className} role='button' onClick={this.toggle} id="signup_1">
          {this.props.title}
        </span>
      </React.Fragment>
    );
  }
}

export default Register;