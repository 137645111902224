import React, { useEffect, useState } from 'react'
import { GET_PLAYER_BET_LIMIT_URL, getValuebet } from '../../../../../../API-Handle/services/auth_curd';
import { Modal } from 'reactstrap';
import MarketLimits from './Comps/MarketLimits';
import SportLimits from './Comps/SportLimits';

export default function LimitModal({ club, item, limitModal, toggleLimitModal }) {
    const [sportBetLimits, setSportBetLimits] = useState([]);
    const [polyBetLimits, setPolyBetLimits] = useState([]);
    const [activeSubTab, setActiveSubTab] = useState("1");

    const fetchPlayerData = async () =>{
        let request_url = `${GET_PLAYER_BET_LIMIT_URL}?club_id=${club.id}&user_id=${item?.id}`;
        let res = await getValuebet(request_url);
        if (res){
            setPolyBetLimits(res.market_bet_limits)
            let limits = res.bet_limits;
            let player_bet_limits = [];
            Object.keys(limits).forEach(element => {
                player_bet_limits.push(limits[element]);
            });
            setSportBetLimits(player_bet_limits);
        }
    }

    useEffect(() => {
        if (limitModal) fetchPlayerData();
        // eslint-disable-next-line
    }, [limitModal])

    return (
        <>
            <Modal isOpen={limitModal} toggle={toggleLimitModal} className="modal-dialog-centered modal-lg">
                <div className="modal-header bg-vb-dark">
                    <h5 className="modal-title my-0">Update {item.username}'s Bet Limits</h5>
                    <button onClick={toggleLimitModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="card text-start bg-vb-dark px-3">
                    <div className='ml-2 mt-3'>
                        <div>
                            <div className="btn-group" role="group">
                                <button type="button" className={activeSubTab === '1' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setActiveSubTab('1')}>Sports</button>
                                <button type="button" className={activeSubTab === '2' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setActiveSubTab('2')}>Markets</button>
                            </div>
                            
                        </div>
                    </div>
                    
                    {activeSubTab === "1" ?
                        <SportLimits
                            item={item}
                            club={club}
                            limits={sportBetLimits}
                            setUpdatedLimits={setSportBetLimits}
                            toggleModal={toggleLimitModal}
                        /> :
                        <MarketLimits 
                            item={item} 
                            club={club}
                            limits={polyBetLimits} 
                            toggleLimitModal={toggleLimitModal} 
                        />
                    }
                </div>
            </Modal>
        </>
    )
}
