import React from 'react'
import { Modal } from "reactstrap"

export default function ChallengeFAQ({ isVisible, modToggle }) {
    return (
        <Modal isOpen={isVisible} toggle={modToggle} className="modal-dialog-centered modal-xl">
            <div className="modal-header bg-vb-dark">
                <h5 className="modal-title my-0"> Valuebet Challenge FAQ</h5>
                <button onClick={modToggle} type="button" className="text-white btn-close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className='modal-body letter-space'>
                <details open>
                    <summary className='vb-text-orange font-size-16px'><b>What are the Valuebet.App Free Play Challenges? </b></summary>
                    <p>The Valuebet.App Free Play Challenges is our way to reward our users. Every user is given the same amount of chips every day. Users can make whatever types of predictions picks they want throughout the day. Top Daily and Monthly Winners are rewarded with Valuebet Coins.</p>
                </details>
                <details open>
                    <summary className='vb-text-orange font-size-16px'><b>What picks can I make?</b></summary>
                    <p>Go nuts and make as many picks as you want, including early games or Live games ending soon so that you can get in more picks in afterwards.</p>
                </details>
                <details open>
                    <summary className='vb-text-orange font-size-16px'><b>What are Valuebet Coins?</b></summary>
                    <p>Valuebet Coins is our platform currency and is required to upgrade clubs, unlock account features, and buy club chips. The Valuebet Coins are Peer to Peer transferable.</p>
                </details>
                <details open>
                    <summary className='vb-text-orange font-size-16px'><b>How are Daily Winners decided?</b></summary>
                    <p>We rank players by the amount of chips they have once all games for the day have been graded. Rewards are based on rankings.</p>
                </details>
                <details open>
                    <summary className='vb-text-orange font-size-16px'><b>How are Monthly Winners decided?</b></summary>
                    <p>We add the daily winnings of each day of the month that you won more chips than you lost, and rank players based on the total amount for the month. Losing days are not factored in. Rewards are based on rankings.</p>
                </details>
                <details open>
                    <summary className='vb-text-orange font-size-16px'><b>What are the cut off times?</b></summary>
                    <p>Only games that start before 23:59 PST will be counted for that day.</p>
                </details>
                <details open>
                    <summary className='vb-text-orange font-size-16px'><b>What are the payouts?</b></summary>
                    <p>Top 10% of all daily active players receive 10% of their daily winnings in Valuebet Coins. Top 5% of monthly winners receive 5% of their winnings in Valuebet Coins. That’s it!</p>
                </details>
            </div>
        </Modal>
    )
}
