import React, { useState, useEffect } from 'react'
import SmSpinner from '../../../../../../Components/CustLoader/SmSpinner';
import { GET_CLUB_POLYBET_LIMITS_URL, getValuebet, postValuebet, UPDATE_CLUB_POLYBET_LIMIT_URL} from '../../../../../../API-Handle/services/auth_curd';
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert';

const MarketOddLimits = ({ club, toggleClubSetting }) => {
  const [limits, setLimits] = useState({
    min_bet_all: 0,
    max_bet_all: 0,
    max_player_exposure: 0,
    max_club_exposure: 0
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchLimits = async () => {
        const res = await getValuebet(GET_CLUB_POLYBET_LIMITS_URL + club.id)
      if (res) {
        setLimits(res)
      }
    }
    fetchLimits()
  }, [])

  const updateValue = (value, field) => {
    setLimits(prev => ({
      ...prev,
      [field]: value === '' ? '' : parseInt(value) || 0
    }));
  };

  const handleSave = async () => {
    setLoader(true);
    try {
      let res = await postValuebet(UPDATE_CLUB_POLYBET_LIMIT_URL, {
        club_id: club.id,
        market_bet_limits: limits
      });
      if (res) {
        successNotification(res.message);
      }
    } catch (error) {
      console.error('Error updating limits:', error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="container-fluid px-0 mt-3">
        <div className="row g-3">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="text-nowrap mb-2 text-white">Min Bet All</label>
              <input 
                type="number" 
                min={0} 
                value={limits.min_bet_all}
                className="form-control form-control-sm bg-dark text-white border-secondary" 
                placeholder="Amount" 
                onChange={(e) => updateValue(e.target.value, "min_bet_all")} 
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="text-nowrap mb-2 text-white">Max Bet All</label>
              <input 
                type="number" 
                min={0} 
                value={limits.max_bet_all}
                className="form-control form-control-sm bg-dark text-white border-secondary" 
                placeholder="Amount" 
                onChange={(e) => updateValue(e.target.value, "max_bet_all")} 
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="text-nowrap mb-2 text-white">Max Player Exposure</label>
              <input 
                type="number" 
                min={0} 
                value={limits.max_player_exposure}
                className="form-control form-control-sm bg-dark text-white border-secondary" 
                placeholder="Amount" 
                onChange={(e) => updateValue(e.target.value, "max_player_exposure")} 
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label className="text-nowrap mb-2 text-white">Max Club Exposure</label>
              <input 
                type="number" 
                min={0} 
                value={limits.max_club_exposure}
                className="form-control form-control-sm bg-dark text-white border-secondary" 
                placeholder="Amount" 
                onChange={(e) => updateValue(e.target.value, "max_club_exposure")} 
              />
            </div>
          </div>
        </div>
      </div>
      <div className="text-end mt-4">
        {loader ? 
          <button type="button" className="btn btn-danger btn-orange btn-sm">
            <SmSpinner inline={true} />
          </button>
          : 
          <button 
            type="button" 
            className="btn btn-danger btn-orange btn-sm" 
            onClick={handleSave}
          >
            Save changes
          </button>
        }
        &nbsp;
        <button 
          onClick={toggleClubSetting} 
          type="button" 
          className="btn btn-secondary btn-sm"
        >
          Cancel
        </button>
      </div>
    </>
  )
}

export default MarketOddLimits