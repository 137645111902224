import React, { useState } from 'react'
import SportLimits from './SportLimits';
import MarketLimits from './MarketLimits';

export default function Index({club, toggleClubSetting}) {
    const [activeSubTab, setActiveSubTab] = useState("1");
    return (
        <>
            <div className='mb-2'>
                <div>
                    <div className="btn-group" role="group">
                        <button type="button" className={activeSubTab === '1' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setActiveSubTab('1')}>Sports</button>
                        <button type="button" className={activeSubTab === '2' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setActiveSubTab('2')}>Markets</button>
                    </div>
                </div>
            </div>
            <div>
                {activeSubTab === '1' ? 
                    <SportLimits club={club} toggleClubSetting={toggleClubSetting}/> : 
                    <MarketLimits club={club} toggleClubSetting={toggleClubSetting}/>
                }
            </div>
        </>
    )
}
