import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  valueMarketDetails: {
    bet_info: {
      bet_type: "Straight",
      risking: 100,
      to_win: 1000,
    },
    bets: [],
  },
  mobileView: false,
  filtersData: [],
  subCatList: [],
  eventsData: [],
  toggleFilter: false,
  firstSection: false,
  mobileScreen: {
    step1: false,
    step2: true,
    step3: false,
  },
  noDataKeyWord: "",
  EventLoading: false,
  subCategory: "",
  mainCategory: "all",
  eventCheck: null,
  marketLimit: {},
  filterType: "americanOdds",
  orderValue:'volume24hr',
  favEvents:[],
  showFav:false
};

const valuemarketSlice = createSlice({
  name: "valuemarket",
  initialState,
  reducers: {
    setMobileScreen: (state, action) => {
      // Reset all steps to false
      Object.keys(state.mobileScreen).forEach((key) => {
        state.mobileScreen[key] = false;
      });

      // Set the step from the payload to true
      state.mobileScreen[action.payload] = true;
    },
    addBet: (state, action) => {
      state.valueMarketDetails.bets.push(action.payload); // Push the new bet into the bets array.
    },
    toggleFilterType: (state) => {
      state.filterType =
        state.filterType === "americanOdds" ? "probability" : "americanOdds";
    },
    updateBets: (state, action) => {
      // Replace the bets array immutably
      state.valueMarketDetails.bets = action.payload.map((bet) => ({ ...bet }));
    },
    removeBet: (state, action) => {
      const { odd_id } = action.payload;
      state.valueMarketDetails.bets = state.valueMarketDetails.bets.filter(
        (bet) => bet.odd_id !== odd_id
      ); // Remove the bet with the specified odd_id.
    },
    mobileViewBtn: (state, action) => {
      state.mobileView = action.payload; // Replace bets with updated array.
    },
    updateBetInfo: (state, action) => {
      const { odd_id, risking, to_win } = action.payload;

      // Find the bet by `odd_id` and update its properties
      const betIndex = state.valueMarketDetails.bets.findIndex(
        (bet) => bet.odd_id === odd_id
      );
      if (betIndex !== -1) {
        state.valueMarketDetails.bets[betIndex].risking = risking;
        state.valueMarketDetails.bets[betIndex].to_win = to_win;
      }
    },
    updateBetType: (state, action) => {
      state.valueMarketDetails.bet_info.bet_type = action.payload;
    },
    clearBets: (state) => {
      state.valueMarketDetails.bets = [];
    },
    setSubCatList: (state, action) => {
      state.subCatList = action.payload;
    },
    setMarketLimitData: (state, action) => {
      state.marketLimit = action.payload;
    },
    setFavEvents: (state, action) => {
      state.favEvents = action.payload;
   },
    setEventsCheck: (state, action) => {
      state.eventCheck = action.payload;
    },
    setEventsData: (state, action) => {
      if (action.payload.append) {
        state.eventsData = [...state.eventsData, ...action.payload.data];
      } else {
        state.eventsData = action.payload.data;
      }
    },
    setClearEventData: (state, action) => {
      state.eventsData = [];
    },
    setSearchKeyword: (state, action) => {
      state.noDataKeyWord = action.payload;
    },
    setSubCategory: (state, action) => {
      state.subCategory = action.payload;
    },
    setOrderValue: (state, action) => {
      state.orderValue = action.payload;
    },
    setMainCategory: (state, action) => {
      state.mainCategory = action.payload;
    },
   setFiltersData: (state, action) => {
      state.filtersData = action.payload;
    },
    setEventLoading: (state, action) => {
      state.EventLoading = action.payload;
    },
    setShowFav: (state) => {
      state.showFav = !state.showFav;
    },

    setFilterToggle: (state, action) => {
      state.toggleFilter = action.payload;
    },
    setFirstSectionToggle: (state, action) => {
      state.firstSection = action.payload;
    }
  },
});

export const {
  setClearEventData,
  setShowFav,
  setOrderValue,
  toggleFilterType,
  setMarketLimitData,
  setFavEvents,
  setEventsCheck,
  setMainCategory,
  setSubCategory,
  setEventLoading,
  setSubCatList,
  setSearchKeyword,
  setMobileScreen,
  setFirstSectionToggle,
  setEventsData,
  setFilterToggle,
  setFiltersData,
  addBet,
  updateBets,
  mobileViewBtn,
  removeBet,
  updateBetInfo,
  updateBetType,
  clearBets,
} = valuemarketSlice.actions;

export default valuemarketSlice.reducer;
