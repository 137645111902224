import { Check, LockOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import CustTooltip from '../../../../../../Components/CustComp/CustTooltip';
import { Checkbox, FormControlLabel } from '@mui/material';
import { postValuebet, UPDATE_HARD_PRICING_STATUS_URL, UPDATE_VIG_TAX_URL } from '../../../../../../API-Handle/services/auth_curd';
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert';

const VigTax = ({ clubInfo }) => {
    const [vigTaxPercent, setVigTaxPercent] = useState(clubInfo.vig_tax);
    const [hardPricing, setHardPricing] = useState(clubInfo.hard_pricing);

    const updateVigTax = async (e) => {
        e.preventDefault();
        const data = { vig_tax: vigTaxPercent }
        let res = await postValuebet(`${UPDATE_VIG_TAX_URL + clubInfo.id}`, data);
        if (res) {
            successNotification(res.message);
        }
    }

    const updateHardPricing = async (e) => {
        const newValue = e.target.checked;
        const data = { hard_pricing_status: newValue }
        let res = await postValuebet(`${UPDATE_HARD_PRICING_STATUS_URL + clubInfo.id}`, data);
        if (res) {
            successNotification(res.message);
            setHardPricing(newValue)
        }
    }

    useEffect(() => {
        setHardPricing(clubInfo.hard_pricing || false)
        setVigTaxPercent(clubInfo.vig_tax || '')
    }, [clubInfo])

    return (
        <>
        <label className='text-white pb-2 d-flex gap-1'>ValueMarkets: {!clubInfo?.level_details?.value_market && <div className='d-flex '><LockOutlined  fontSize='small' className='icon-bet-no' /><div className="text-warning font-size-10px mb-1">Preview</div></div>}</label>
        {/* <div className={`d-flex flex-wrap gap-3 justify-content-between align-items-center ${!clubInfo?.level_details?.value_market && "section-disable"}`}> */} {/* TODO: Temp Unlock */}
        <div className='d-flex flex-wrap gap-2 justify-content-between align-items-center'>
            <div className='flex-grow-1'>
                <div className='d-flex gap-2  align-items-center'>
                    <div className='text-white pb-1'>Spread/Vig Tax:</div>
                    <div className='flex-grow-1' style={{maxWidth:'300px'}}>
                        <input
                            type="number"
                            placeholder='Vig Tax Percentage'
                            className="form-control form-control-sm bg-dark text-white border-secondary"
                            aria-describedby='vig-tax-percent'
                            value={vigTaxPercent}
                            onChange={(e) => setVigTaxPercent(e.target.value)}
                            min={0}
                            max={100}
                            required={true}
                        />
                    </div>
                    <div>
                        <button type="button" className="btn btn-danger btn-orange btn btn-sm" onClick={updateVigTax}> <Check fontSize='mediam' /> </button>
                    </div>
                    <CustTooltip
                        id={clubInfo.id + "vig-tax"}
                        text="This is the amount of cents added to Polymarket's Price. For example, if the price is 50c per share on Polymarket, the displayed price will be 55c per share."
                        customClass="d-flex align-items-center ms-2"
                    />
                </div>
            </div>
            <div className='d-flex  align-items-center'>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={hardPricing}
                            onChange={updateHardPricing}
                            name="Hard Pricing"
                            sx={{fontSize: "1rem"}}
                        />
                    }
                    label="Hard Pricing: "
                    labelPlacement='start'

                    sx={{ color: "white", padding: '0px',margin:'0px' }}
                />
                <CustTooltip
                    id={clubInfo.id + "hard-pricing"}
                    text="If selected, the vig tax will be added even if the Spread is already larger than the Vig Tax. If unchecked, the system will use Polymarket's price if the spread is greater than the Vig Tax added. For example, if Polymarket's price is 56 (-127) & 56 (-127), Hard Pricing of 5 will adjust the prices to 61 (-156) & 61 (-156) while the non-Hard Price will be 56 & 56 since the spread is 12 and is greater than the Vig Tax of 10 if the Vig Tax is set at 5 (amount set of 5 x 2)"
                    customClass="d-flex align-items-center ms-2"
                />
            </div>
        </div>
        </>
    )
}

export default VigTax