import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logoSm from "../../Static/UserManagement/images/common/logov.png";
import ProfileMenu from "./profileMenu";
import NotiMenu from "../CustComp/NotiMenu";
import ValuebetContext from "../../context/Valuebet/ValuebetContext";
// import SearchBox from '../../components/Sportbook/Pages/Sports/Odds/SearchBox';
// import FilterBtn from '../../components/Sportbook/Pages/Sports/Odds/FilterBtn';
// import LoopIcon from '@mui/icons-material/Loop';
import Alerts from "../PublicModals/Alerts";
import { LockOutlined } from "@mui/icons-material";

const RightItem = ({ title, value }) => {
  return (
    <div className="menu-item-head">
      <strong>{title}</strong>
      <strong className="ms-1">{value}</strong>
    </div>
  );
};

const NavigationBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { activeClub, setActiveClub, fetchSingleClub, onFilterClick } =
    useContext(ValuebetContext);
  const [playerChips, setPlayerChips] = useState(0);
  const [LockedChips, setLockedChips] = useState(0);
  const searchParams = new URLSearchParams(location.search);
  const state = searchParams.get("state") ? JSON.parse(decodeURIComponent(searchParams.get("state"))) : null;

  const updateChips = () => {
    setPlayerChips(activeClub?.user_chips?.player_chips);
    setLockedChips(activeClub?.user_chips?.locked_chips);
  };

  useEffect(() => {
      let club = location?.state?.club || state?.club;
      if (club) {
          setActiveClub(club)
          fetchSingleClub(club.id);
      } else {
          navigate("/dashboard")
      }
      // eslint-disable-next-line
  }, [])

  useEffect(() => {
    updateChips();
    // eslint-disable-next-line
  }, [activeClub]);

  return (
    <React.Fragment>
      <Alerts />
      <header id="topnav" className="container-fluid">
        <div className="topbar-main">
          <div className="logo">
            {window.location.pathname !== "/sportbook/club-reports" ? (
              <span onClick={onFilterClick} role="button">
                <img src={logoSm} alt="" className="logo-small" />
                <img src={logoSm} alt="" className="logo-large" />
                {/* <span className="beta-icon text-warning">Beta</span> */}
              </span>
            ) : (
              <>
                <span
                  role={"button"}
                  onClick={() => {
                    window.history.go(-1);
                  }}
                  className="me-3 back-button"
                >
                  <i className="fas fa-angle-left text-white back-button-size"></i>
                </span>
                <span
                  onClick={onFilterClick}
                  className="app-logo-sport"
                  role="button"
                >
                  <img src={logoSm} alt="" className="logo-small" />
                  <img src={logoSm} alt="" className="logo-large" />
                  {/* <span className="beta-icon beta-icon-110-left text-warning">Beta</span> */}
                </span>
              </>
            )}

            {window.location.pathname !== "/dashboard" ? (
              <span className="club-title-sport">
                {activeClub?.name?.toLocaleUpperCase()}
              </span>
            ) : null}
          </div>
         

          <div className="list-right">
            <RightItem title={"Player Chips:"} value={playerChips} />
            <RightItem title={"Locked Chips:"} value={LockedChips} />
          </div>
          <div className="menu-extras-sport topbar-custom">
            <ul className="list-inline float-end mt-2 d-flex align-items-center">
              <NotiMenu />
              <ProfileMenu />
            </ul>
          </div>
          
          <div className="clearfix"></div>
        </div>
        {window.location.pathname !== "/sportbook/club-reports" ? (
            <div className="list-nav">
              <NavLink
                to="/dashboard"
                className={"list-item-nav text-decoration-none"}
              >
                HOME
              </NavLink>
              <NavLink
                to="/sportbook/sports"
                state={{ club: activeClub }}
                className={`text-decoration-none ${
                  location.pathname === "/sportbook/sports"
                    ? "list-item-nav-active"
                    : "list-item-nav"
                }`}
              >
                SPORTS
              </NavLink>
              {!activeClub?.is_special && 
              <NavLink
                to="/value-markets"
                state={{ club: activeClub }}
                className={ `text-decoration-none d-flex ${location.pathname.startsWith("/value-markets")
                      ? "list-item-nav-active"
                      : "list-item-nav"
                  }`}
              >
                MARKETS {!activeClub?.level_details?.value_market && <div className='d-flex '><LockOutlined sx={{ml:0.5}} fontSize='small' className='icon-bet-no' /><div className="text-warning font-size-10px mb-1">Prev</div></div>}
              </NavLink>}

              <NavLink
                to="/sportbook/reports"
                state={{ club: activeClub }}
                className={`text-decoration-none  ${
                  location.pathname === "/sportbook/reports"
                    ? "list-item-nav-active"
                    : "list-item-nav"
                }`}
              >
                REPORTS
              </NavLink>
              {activeClub?.is_special && (
                <NavLink
                  to="/sportbook/reward-reports"
                  state={{ club: activeClub }}
                  className={`text-decoration-none  ${
                    location.pathname === "/sportbook/reward-reports"
                      ? "list-item-nav-active"
                      : "list-item-nav"
                  }`}
                >
                  <div className="d-flex">
                    REWARDS
                    <sup className="mt-2 text-danger">New</sup>
                  </div>
                </NavLink>
              )}
              {/* <span className='d-flex justify-content-end w-100'>
                                    <SearchBox />
                                    <FilterBtn value={'Clear'} onClick={() => { }} />
                                    <button className="refresh-btn">
                                        <LoopIcon />
                                    </button>
                                </span> */}
            </div>
          ) : null}
      </header>
    </React.Fragment>
  );
};

export default NavigationBar;
