import { Grid, Typography } from "@mui/material";
import SkeletonCard from "../../../../Components/CustLoader/Cardskeleton";
import EventCard from "./EventCard";
import Spinner from "../../../../Components/CustLoader/Spinner";
import { useSelector } from "react-redux";
import { styles } from "../../Css/styles";

const Index = ({
  events,
  eventLoading,
  loadingMore,
  noMoreData,
  scrollContainerRef,
}) => {
  const {showFav, marketLimit} = useSelector((state) => state.valuemarket);

  return (
    
    <Grid my={1} mb={0}   
    ref={scrollContainerRef}
    sx={styles.listCardBorderWrapper}>
    <Grid container sx={styles.listCardWrapper}>
      {(eventLoading || Object.keys(marketLimit).length === 0) ? 
      Array.from({ length: 12 }).map((_, index) => <SkeletonCard key={index} />) :
      events?.length === 0 ? 
      <Typography sx={{ py: 2, width: "100%", textAlign: "center", fontSize: "14px", fontFamily: "inherit" }}>
          {showFav && 'No Favourite Data Available'}
        </Typography>
      :
        events?.map((item) => <EventCard key={item.id} item={item}/>)}

      {loadingMore && (
        <Typography sx={{background:'rgb(0 0 0 / 85%)',py:3,  position: "sticky", bottom: 0, width: "100%", textAlign: "center", fontSize: "12px" }}>
          <Spinner my="0" py="0" />
        </Typography>
      )}
      {noMoreData && events.length > 0 && (
        <Typography sx={{ py: 2, width: "100%", textAlign: "center", fontSize: "14px", fontFamily: "inherit" }}>
          No more data available
        </Typography>
      )}
    </Grid>
    </Grid>
  );
};

export default Index;
