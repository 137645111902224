import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearBets } from '../../../../../redux/features/valuemarketSlice'
import ValuebetContext from '../../../../../context/Valuebet/ValuebetContext'

export default function NavigateToMarkets({data}) {
    const dispatch = useDispatch();
    const {fetchSingleClub, activeClub, setRootLoader } = useContext(ValuebetContext);
    const [isClicked, setIsClicked] = useState(false);

    const navigateToDetails = async () => {
        setIsClicked(true);
        setRootLoader(true);
        fetchSingleClub(data.club);
    }
    
    useEffect(() => {
        if(isClicked && activeClub) {
            setRootLoader(false);
            dispatch(clearBets());
            // navigate(`/value-markets/${data?.bets[0]?.bet?.event_id}`, { state: { club: activeClub } });
            const eventId = data?.bets[0]?.bet?.event_id;
            const encodedState = encodeURIComponent(JSON.stringify({ club: activeClub }));
            window.open(`/value-markets/${eventId}?state=${encodedState}`, "_blank"); 
          }
    // eslint-disable-next-line
    }, [isClicked, activeClub])

  return (
    <Link to={'#'}  className='list-item-nav text-nowrap me-0 betDetailLink' onClick={navigateToDetails}>Bet Details</Link>
  )
}
