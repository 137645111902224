import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Button,
  ButtonGroup,
  Container,
  CircularProgress,
  Box,
} from "@mui/material";
import axios from "axios";
import moment from "moment";

const timeIntervals = [
  { label: "1H", value: "1h", fidelity: "1" },
  { label: "6H", value: "6h", fidelity: "1" },
  { label: "1D", value: "1d", fidelity: "5" },
  { label: "1W", value: "1w", fidelity: "30" },
  { label: "1M", value: "1m", fidelity: "180" },
  { label: "ALL", value: "all", fidelity: "720" },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#0D1B2A",
          fontFamily: "inherit",
          padding: "10px",
          border: "1px solid #00A896",
          borderRadius: "5px",
          color: "#FFFFFF",
          textAlign:'start',
        }}
      >
        <p>Time: {payload[0].payload.time}</p>
        <p>Price: {payload[0].value.toFixed(2)}%</p>
      </div>
    );
  }
  return null;
};

const MarketGraphSingle = ({ marketId }) => {
  const [data, setData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(timeIntervals[5]);
  const [initialLoading, setInitialLoading] = useState(true);

 
const fetchData = async (interval) => {
  try {
    const response = await axios.get(
      `https://clob.polymarket.com/prices-history?interval=${interval.value}&market=${marketId[0]}&fidelity=${interval.fidelity}`
    );
    if (response.data) {
      setData(
        response.data.history.map((point) => {
          let t = moment.utc(point.t * 1000);
          return {
            time: ["1h", "6h", "1d"].includes(interval.value) ? t.format("hh:mm a") : ["1w","1m"].includes(interval.value) ? t.format("MMM D") : t.format("MMM D"),
            price: point.p * 100,
          };
        })
      );
    } else {
      setData([]);
    }
    setInitialLoading(false); // Set to false after the first load
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

  useEffect(() => {
    fetchData(selectedInterval);
    // eslint-disable-next-line
  }, [selectedInterval]);

  const newMinValue = Math.floor(
    Math.min(...data.flatMap((d) => Object.values(d).slice(1))) / 5
  ) * 5;
  
  const newMaxValue = Math.ceil(
    Math.max(...data.flatMap((d) => Object.values(d).slice(1))) / 5
  ) * 5;
 
  
  return (
    <Container sx={{ textAlign: "center", mt: 2,p:0 }}>
      {initialLoading ? (
                <Box sx={{height:'250px',width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <CircularProgress mt={2} />
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={250}  style={{marginLeft:'-10px'}}>
          <AreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#1B263B" />
            <XAxis
              dataKey="time"
              stroke="#E0E1DD"
              tick={{ fontSize: 12 }}
              axisLine={{ stroke: "#E0E1DD" }}
              tickLine={false}
            />
              <YAxis  orientation="left" domain={[newMinValue, newMaxValue]} stroke="#888" tickFormatter={(tick) => `${tick}%`} tick={{ fontSize: 12 }} interval={0} tickCount={Math.floor(newMaxValue / 5) + 1} />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="price"
              strokeWidth={2}
             stroke="#d52b00"
              fill="#000"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
      <ButtonGroup variant="contained" sx={{ mt: 3 }}>
        {timeIntervals.map((interval) => (
          <Button
            sx={{
              color: "#fff",
              background:
                selectedInterval.value === interval.value
                  ? "#d52b00"
                  : "#1B263B",
              borderColor: "#999!important",
              "&:hover": {
                background: "#d52b00",
              },
            }}
            key={interval.value}
            onClick={() => setSelectedInterval(interval)}
            color={
              selectedInterval.value === interval.value ? "primary" : "secondary"
            }
          >
            {interval.label}
          </Button>
        ))}
      </ButtonGroup>
    </Container>
  );
};

export default MarketGraphSingle;
