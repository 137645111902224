import React, { useState } from "react";
import { MenuItem, Select, CircularProgress } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import BarChartIcon from "@mui/icons-material/BarChart";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { styles } from "../../Css/styles";
import { useDispatch, useSelector } from "react-redux";
import { setOrderValue } from "../../../../redux/features/valuemarketSlice";

const options = [
  { label: "Trending", value: "volume24hr", icon: <TrendingUpIcon /> },
  { label: "Volume", value: "volume", icon: <BarChartIcon /> },
  { label: "Newest", value: "startDate", icon: <NewReleasesIcon /> },
  { label: "Ending soon", value: "endDate", icon: <HourglassEmptyIcon /> }
];

const OrderDropdown = () => {
const dispatch = useDispatch();
const orderValue = useSelector(
    (state) => state.valuemarket.orderValue
  );

  return (
    <Select
      value={orderValue}
      onChange={(e) => dispatch(setOrderValue(e.target.value))}
      displayEmpty
      sx={{...styles.filterOption,
        '.MuiSelect-select':{
        py:0.8,
        px:2,
      },
      'fieldset':{
        border:'none'
    }}}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.icon} &nbsp; {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default OrderDropdown;
