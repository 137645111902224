// eslint-disable-next-line
const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
const validUsernameRegex = RegExp(/^[a-zA-Z][a-zA-Z0-9]*$/)
const validReferralRegex = RegExp(/^[a-zA-Z0-9]*$/)

// ================== Input Validation Management Functions Start =======================

export function manageAllInputsErrors(e) {
    let value = e.target.value;
    let username_error = '';
    let email_error = '';
    let npass_error = '';
    let cnpass_error = '';
    let otp_error = '';
    let password_error = '';
    let club_id_error = '';
    let referral_id_error = '';
    switch (e.target.name) {
        case 'username':
            username_error =
                value.length === 0
                    ? 'Please enter username.'
                    : value.length < 4 || value.length > 16
                        ? 'Username must be 4-16 character without spaces.'
                        : validUsernameRegex.test(value)
                            ? ''
                            : 'Username must be composed only with letter and numbers';
            break;

        case 'email':
            email_error =
                value.length === 0
                    ? 'Please enter email.'
                    : validEmailRegex.test(value)
                        ? ''
                        : 'Invalid email address.';
            break;

        case 'npass':
            npass_error =
                value.length === 0
                    ? 'Please enter new password'
                    : value.trim() !== value
                        ? 'Password should not contain leading or trailing spaces'
                        : 7 < value.length && value.length < 17
                            ? ''
                            : 'Password must be between 8-16 characters';
            break;

        case 'cnpass':
            cnpass_error =
                value.length === 0
                    ? 'Please enter confirm new password'
                    : value.trim() !== value
                        ? 'Password should not contain leading or trailing spaces'
                        : 7 < value.length && value.length < 17
                            ? ''
                            : 'Password must be between 8-16 characters';
            break;
        case 'otp':
            otp_error =
                value.length === 0
                    ? 'Please enter OTP'
                    : value.length === 6
                        ? ''
                        : 'Invalid OTP';
            break;
        case 'password':
            password_error =
                value.length === 0
                    ? 'Please enter password'
                    : value.trim() !== value
                        ? 'Password should not contain leading or trailing spaces'
                        : 8 > value.length || value.length > 16
                            ? 'Password must be between 8-16 characters'
                            : '';
            break;
        case 'club_id':
            club_id_error =
                value.length === 0
                    ? 'Please enter Club ID.'
                    : value.length === 8
                        ? ''
                        : 'Club ID should be 8 characters.';
            break;
        case 'referral_id':
            referral_id_error =
                value.length === 0
                    ? ''
                    : value.length === 8
                        ? ''
                        : 'Referral ID should be 8 characters or blank.';
            break;
        default:
            break;
    }
    return { username_error, email_error, npass_error, cnpass_error, otp_error, password_error, club_id_error, referral_id_error }
}


export function beforePassUpdate(npass, cnpass) {
    let npass_error =
        npass.length === 0
            ? 'Please enter new password'
            : npass.trim() !== npass
                ? 'Password should not contain leading or trailing spaces'
                : 7 < npass.length && npass.length < 17
                    ? ''
                    : 'Password must be between 8-16 characters';

    let cnpass_error =
        cnpass.length === 0
            ? 'Please enter confirm new password'
            : cnpass.trim() !== cnpass
                ? 'Password should not contain leading or trailing spaces'
                : 7 < cnpass.length && cnpass.length < 17
                    ? ''
                    : 'Password must be between 8-16 characters';

    return { npass_error, cnpass_error };
}

export function beforeSendOtp(email) {
    let email_error =
        email.length === 0
            ? 'Please enter email.'
            : validEmailRegex.test(email)
                ? ''
                : 'Invalid email address.';

    return { email_error };
}

export function beforeVerifyEmail(email, otp) {
    let email_error =
        email.length === 0
            ? 'Please enter email.'
            : validEmailRegex.test(email)
                ? ''
                : 'Invalid email address.';

    let otp_error =
        otp.length === 0
            ? 'Please enter OTP'
            : otp.length === 6
                ? ''
                : 'Invalid OTP';

    return { email_error, otp_error };
}

export function beforeLogin(username, password) {
    let username_error =
        username.length === 0
            ? 'Please enter username.'
            : username.length < 4 || username.length > 16
                ? 'Username must be 4-16 character without spaces.'
                : validUsernameRegex.test(username)
                    ? ''
                    : 'Username must be composed only with letter and numbers';

    let password_error =
        password.length === 0
            ? 'Please enter password'
            : password.trim() !== password
                ? 'Password should not contain leading or trailing spaces'
                : 8 > password.length || password.length > 16
                    ? 'Password must be between 8-16 characters'
                    : '';

    return { username_error, password_error };
}

export function beforeSignup(username, email, password, referral) {
    let username_error =
        username.length === 0
            ? 'Please enter username.'
            : username.length < 4 || username.length > 16
                ? 'Username must be 4-16 character without spaces.'
                : validUsernameRegex.test(username)
                    ? ''
                    : 'Username must be composed only with letter and numbers';

    let email_error =
        email.length === 0
            ? 'Please enter email.'
            : validEmailRegex.test(email)
                ? ''
                : 'Invalid email address.';


    let password_error =
        password.length === 0
            ? 'Please enter password'
            : password.trim() !== password
                ? 'Password should not contain leading or trailing spaces'
                : 8 > password.length || password.length > 16
                    ? 'Password must be between 8-16 characters'
                    : '';

    let referral_error =
        referral.length === 0
            ? ''
            : !(validReferralRegex.test(referral))
                ? "Referral's User ID should not be contains any space or special characters"
                : referral.length !== 8
                    ? "Referral's User ID should be 8 characters"
                    : '';


    return { username_error, email_error, password_error, referral_error };
}

export function beforeClubRequest(club_id, referral_id) {
    let club_id_error =
        club_id.length === 0
            ? 'Please enter Club ID.'
            : club_id.length === 8
                ? ''
                : 'Club ID should be 8 characters.';

    let referral_id_error =
        referral_id.length === 0
            ? ''
            : referral_id.length === 8
                ? ''
                : 'Referral ID should be 8 characters or blank.';

    return { club_id_error, referral_id_error };
}

// =================== Input Validation Management Functions End ======================== 



// =================== Text Utils Management Functions Start ========================

export const refinHeading = (val) => {
    // Remove leading spaces and replace consecutive spaces with a single space
    const newValue = val.replace(/^\s+/, '').replace(/\s+/g, ' ');
    return newValue.toUpperCase();
}

export const refineTitle = (val) => {
    // Remove leading spaces and replace consecutive spaces with a single space
    const newValue = val.replace(/^\s+/, '').replace(/\s+/g, ' ');
    return newValue;
}

export const refineUsername = (val) => {
    // Remove single and double spaces and convert text into lower case
    const newValue = val.replace(/\s/g, '');
    return newValue.toLowerCase();
}

export const noSpaceCapitalize = (val) => {
    // Remove single and double spaces and convert text into upper case
    const newValue = val.replace(/\s/g, '');
    return newValue.toUpperCase();
}

export const positiveNumberOnly = (val) => {
    // Replace any non-numeric characters except the decimal point with an empty string
    let newValue = val.replace(/[^.\d]|(?<=\.\d*)\D+/g, '');
    if (!(newValue.endsWith(".") || newValue.endsWith("0"))) {
        let parsed_val = Number.parseFloat(newValue);
        if (!isNaN(parsed_val)) return parsed_val;
    }
    return newValue;
}

export const numberOnly = (val) => {
    // Replace any non-numeric characters except the decimal point and minus sign
    let newValue = val.replace(/[^\d.-]|(?<=^-?\d*\.\d*)\D+/g, '');
    // Ensure minus sign only appears at the beginning and is not repeated
    newValue = newValue.replace(/^-+/, '-');
    // Ensure decimal point appears only once
    newValue = newValue.replace(/\.+/g, '.');

    if (!(newValue.endsWith(".") || newValue.endsWith("0"))) {
        let parsed_val = Number.parseFloat(newValue);
        if (!isNaN(parsed_val)) return parsed_val;
    }
    return newValue;
}

export const shrinkNumber = num => {
    num = num.toString().replace(/[^0-9.]/g, '');
    if (num < 10000) {
        return num;
    }
    let si = [
        { v: 1E3, s: "K" },
        { v: 1E6, s: "M" },
        { v: 1E9, s: "B" },
        { v: 1E12, s: "T" },
        { v: 1E15, s: "P" },
        { v: 1E18, s: "E" }
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
        if (num >= si[index].v) {
            break;
        }
    }
    return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
};

// =================== Text Utils Management Functions End ======================== 


// ========================= Quick Task Start =====================================

export const clearQueryStringFromURL = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.forEach((value, key) => {
        searchParams.delete(key);
    });
    window.history.replaceState(null, '', `${window.location.pathname}`);
}

export const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const getAvailableWidth = (isModal) => {
    let temp_width = 0;
    if (isModal) {
      temp_width = window.innerWidth < 1280 ? window.innerWidth - 50 : 1240;
    } else {
      temp_width = window.innerWidth < 1400 ? window.innerWidth - 70 : 1330;
    }
    return temp_width;
  }

// ========================= Quick Task End =======================================