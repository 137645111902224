import { SwapVert } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { styles } from "../../Css/styles";
import { useDispatch, useSelector } from "react-redux";
import { toggleFilterType } from "../../../../redux/features/valuemarketSlice";

const ToggleFilterButton = () => {
    const dispatch = useDispatch();
    const {filterType} = useSelector((state) => state.valuemarket);
  return (
       <Button
       onClick={() => dispatch(toggleFilterType())}
       endIcon={<SwapVert />}
       variant="contained"
       sx={styles.filterOption}
       size="small"
     > <Box component='span' sx={{
      display:{
        sm:'block',
        lg:'block',
        xs:'none'
      }
     }}>Type : &nbsp;</Box>
       {filterType === "americanOdds" ? "American Odds" : "Probability"}
     </Button>
  );
};

export default ToggleFilterButton;
