import React, { useEffect, useState } from 'react'
import PublicHeader from '../../../Components/PublicHeader';
import AOS from 'aos';
import 'aos/dist/aos.css';
import wervaluebet from '../../../Static/UserManagement/images/landing/we-r-valuebet.png';
import our_challenges from '../../../Static/UserManagement/images/landing/our_challenges_img.svg';
import create_club from '../../../Static/UserManagement/images/landing/create_a_club.svg';
import join_a_club from '../../../Static/UserManagement/images/landing/join_club.svg';
import RewardImg from '../../../Static/UserManagement/images/landing/reward.png';
import about_logo from '../../../Static/UserManagement/images/landing/about_logo.svg';
import brastool from '../../../Static/UserManagement/images/gaming-logo/barstool.09c4a8a700e8fd6edebc.svg';
import bet365 from '../../../Static/UserManagement/images/gaming-logo/bet365.dd8616538355e118e30f7681ed098441.svg';
import betmgm from '../../../Static/UserManagement/images/gaming-logo/betmgm.a091063b7d6851619206126be2a39be5.svg';
import betriverse from '../../../Static/UserManagement/images/gaming-logo/betrivers.7e1c6fc85478a506ae40801ee72e7706.svg';
import borgata from '../../../Static/UserManagement/images/gaming-logo/borgata.2ea6707a950d3651b2235b242538f4ac.svg';
import caseing from '../../../Static/UserManagement/images/gaming-logo/caesarssportsbook.9207f148faa3724fa905efdca85d62ae.svg';
import draftking from '../../../Static/UserManagement/images/gaming-logo/draftkings.png';
import fandual from '../../../Static/UserManagement/images/gaming-logo/fanduel.282b4530c3974260904f4e4f064a73a2.svg';
import foxbet from '../../../Static/UserManagement/images/gaming-logo/foxbet.123da73b3357a659d8e7c1d83496c5d7.svg';
import pointsbet from '../../../Static/UserManagement/images/gaming-logo/pointsbet.b2aa01a8de27057315f622a3d31a27e0.svg';
import sugarhouse from '../../../Static/UserManagement/images/gaming-logo/sugarhouse.6da3a66f4a576567436b62fa1e21b393.svg';
import thescore from '../../../Static/UserManagement/images/gaming-logo/thescorebet.1c92e457577770dfe83133f06de7bdba.svg';
import unibet from '../../../Static/UserManagement/images/gaming-logo/unibet.png';
import vihill from '../../../Static/UserManagement/images/gaming-logo/williamhill.8cd00ce6a8036ebc638d104e3a504f56.svg';
import wynnnn from '../../../Static/UserManagement/images/gaming-logo/wynnbet.b3cb6e98687e2b6fdf73ba79729b412b.svg';
import airrowbut from '../../../Static/UserManagement/images/common/next.png';
import { useNavigate } from 'react-router';
import HeroSlider from './HeroSlider';
import PublicFooter from '../../../Components/PublicFooter';

export default function Home() {
    const navigate = useNavigate();
    // eslint-disable-next-line
    const [offset_1, setoffset_1] = useState(350);

    useEffect(() => {
        if (localStorage.getItem('authToken') != null) {
            navigate('/dashboard')
        }
        AOS.init();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div id='public-page' className='BG-valuebet'>
                <PublicHeader />
                <div className='layout-mt-65'>
                    <div role="button" onClick={()=> document.getElementById("reward_faq_1").click()} className='vb-banner'>
                        <div className='valuebet-bg-color text-center font-size-18px p-1'>
                            Earn Real Rewards as an Affiliate!
                        </div>
                    </div>
                    <section className='vb_all_detail' data-aos="fade-down" data-aos-duration="1100">
                        <div className="container vb_detail">
                            <div className='row align-items-center'>
                                <div className='col-lg-6 col-md-12 order_1'>
                                    <div className='main_heading'>
                                        <h3 className='heading_v'>Social Sports & Predictions <span className='text_orange'>API Market Aggregator</span></h3>
                                        <p className='desc_v'>Application to Host & Manage <br />a Social Sports & Predictions Market.</p>
                                    </div>
                                    <div className='clubs_button_vb'>
                                        <span className="club_buttons" role="button" id="button-vb" onClick={()=> document.getElementById("signup_1").click() }>FREE TO START<img src={airrowbut} className="img-fluid " alt="club" /></span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 order_2'>
                                    <HeroSlider />
                                </div>
                            </div>

                        </div>
                    </section>
                    {/* we r valuebet csss start */}

                    <section className='valubett_intro'>
                        <div className="container">
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <div data-aos-offset={offset_1} data-aos="zoom-in" data-aos-duration="1500" className='left_section_image'>
                                        <img src={wervaluebet} className="img-fluid " alt="value" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div data-aos-offset={offset_1} data-aos="fade-up" data-aos-duration="1500" className='main_heading'>
                                        <h3 className='heading_v'>Live Polymarket <span className='text_orange'>Odds </span></h3>
                                        <p className='desc_v'>Live and Continuous Polymarket Odds delivered to your Clubs. Custom Odds settings to modify odds according to your preference. There is no one else like us.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* play our chalenges section start */}
                    <section className='our_challenges'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12 order_1'>
                                    <div data-aos-offset={offset_1} data-aos="fade-up" data-aos-duration="2500" className='main_heading'>
                                        <h3 className='heading_v'>Play our <span className='text_orange'>Challenges </span></h3>
                                        <p className='desc_v'>Explore the ValueBet Free Play Challenges - Your ticket to exhilarating Social Gaming action. Unleash Your Sports & Market Prediction Skills and Win Big! Each day, you are granted chips to make free plays and compete for top daily, weekly, and monthly rewards in Valuebet Coins, our platform currency.</p>
                                    </div>
                                    <div className='learn_button_vb'>
                                        <span className="club_buttons" role="button" id="club_vb" onClick={()=> document.getElementById("challenge_faq_1").click() }>LEARN MORE</span>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 order_2'>
                                    <div data-aos-offset={offset_1} data-aos="fade-up" data-aos-duration="2500" className='right_section_image'>
                                        <img src={our_challenges} className="img-fluid " alt="value" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* club section start-------------- */}
                    <section className='join_club'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <div className="club_card" data-aos-offset={offset_1} data-aos="flip-left" data-aos-duration="1500">
                                        <div className='club_image text-center'>
                                            <img src={create_club} className="img-fluid " alt="club" />
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">Host Your Own Club</h5>
                                            <p className="card-text">Host your very own Social Sports & Predictions Market! Invite members to join your Club, send them chips, and let them compete with you to see who's better at analyzing risk probabilities on real world events. As a Club Host, you control the chips.</p>
                                        </div>
                                        <div className='clubs_button_vb'>
                                            <span className="club_buttons" role="button" id="club_vb" onClick={()=> document.getElementById("signup_1").click() }>SIGN UP NOW</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12 Vb_margin'>
                                    <div className="club_card" data-aos-offset={offset_1} data-aos="flip-right" data-aos-duration="1500">
                                        <div className='club_image text-center'>
                                            <img src={join_a_club} className="img-fluid " alt="club" />
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">Join an Existing Club</h5>
                                            <p className="card-text">Apply to an existing club using the Club's ID. Once accepted and given chips, you are set to start playing. Play in multiple clubs and see who's the best. </p>
                                        </div>
                                        <div className='clubs_button_vb'>
                                            <span className="club_buttons" role="button" id="club_vb" onClick={()=> document.getElementById("signup_1").click() }>SIGN UP NOW</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Reward section start */}
                    <section className='reward-section'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <div data-aos-offset={offset_1} data-aos="fade-up" data-aos-duration="2500" className='left_section_image'>
                                        <img src={RewardImg} className="img-fluid " alt="value" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div data-aos-offset={offset_1} data-aos="fade-up" data-aos-duration="2500" className='main_heading'>
                                        <h3 className='heading_v'>Multi-Tier Rewarding <span className='text_orange'>Network</span></h3>
                                        <p className='desc_v'>Refer others using your User ID. You automatically earn Valuebet Coins when your referrels or their referrals win in our challenges.</p>
                                    </div>
                                    <div className='learn_button_vb'>
                                        <span className="club_buttons" role="button" id="club_vb" onClick={()=> document.getElementById("reward_faq_1").click() }>LEARN MORE</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* start new logo section */}
                    <section data-aos-offset={offset_1} data-aos="fade-up" data-aos-duration="2500" className='about_gaming'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-12'>
                                    <div className='gaming_heading'>
                                        <h3 className='heading_v'>About <span className='text_orange'>Sports Gaming </span></h3>
                                        <p className='desc_v'>We are your go-to platform for a Sports & Predictions Markets Management Application. We set ourselves apart by offering a simple and innovative platform that delivers value to users and players. With our cutting-edge technology and unrivaled innovation, you'll consistently get great gaming experience. Join ValueBet today and transform your passion for risk analysis into a rewarding social gaming experience.</p>
                                        <div className='clubs_button_vb'>
                                            <span className="club_buttons" role="button" id="club_vb" onClick={()=> document.getElementById("signup_1").click() }>SIGN UP NOW</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-6'>
                                    <div className='logo_image'>
                                        <img src={about_logo} className="img-fluid " alt="value" />
                                    </div>
                                </div>
                                <div className='col-lg-12 col-md-12'>
                                    <p className='realtime_v'>REAL-TIME ODDS FROM <span className='text_orange'>MAJOR SPORTSBOOKS</span></p>
                                    <div className='sportsbook_label'>
                                        <div className='sportsbook_label_line'>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={brastool} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={bet365} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={betmgm} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={betriverse} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={borgata} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={caseing} className="img-fluid " alt="value" />
                                            </div>
                                        </div>
                                        <div className='sportsbook_label_line'>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={draftking} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={fandual} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={foxbet} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={pointsbet} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={sugarhouse} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={thescore} className="img-fluid " alt="value" />
                                            </div>
                                        </div>
                                        <div className='sportsbook_label_line'>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={unibet} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={vihill} className="img-fluid " alt="value" />
                                            </div>
                                            <div className='sportsbooks-labels__item barstool'>
                                                <img src={wynnnn} className="img-fluid " alt="value" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <PublicFooter />
                </div>
            </div>
        </>
    )
}