import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import facebook from '../../Static/UserManagement/images/social_ic/fb.svg';
import shareIcon from '../../Static/UserManagement/images/social_ic/share.svg';
import instagram from '../../Static/UserManagement/images/social_ic/insta.svg';
import tw_twitter from '../../Static/UserManagement/images/social_ic/twitter.svg';
import lk_linkdin from '../../Static/UserManagement/images/social_ic/linkd.svg';
import CopyText from "../CustComp/CopyText";

const SocialShareModal = ({data}) => {
  const [open, setOpen] = useState(false);
  const url = `${window.location.origin}/social-share/${data.id}`; 
  return (
    <>
    <Modal isOpen={open} toggle={!open} centered>
      <div className="modal-header  bg-vb-dark">
        <h5 className="modal-title my-0"> Share Bet on Social Media</h5>
        <button
          onClick={()=> setOpen(false)}
          type="button"
          className="text-white btn-close"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <ModalBody className="text-center">
        <p>Share this link via</p>
        <div className="d-flex justify-content-center gap-2 mb-3">
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              url
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            // className="btn btn-primary rounded-circle"
          >
            <img src={facebook} className="img-fluid" alt="f_logo" />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
              url
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            // className="btn btn-info rounded-circle"
          >
           <img src={tw_twitter} className="img-fluid" alt="f_logo" />
          </a>
          <a
            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
              url
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            // className="btn btn-success rounded-circle"
          >
           <img src={lk_linkdin} className="img-fluid" alt="f_logo" />
          </a>
          <a
            href={`https://www.instagram.com/?url=${encodeURIComponent(url)}`}
            target="_blank"
            rel="noopener noreferrer"
            // className="btn btn-danger rounded-circle"
          >
            <img src={instagram} className="img-fluid" alt="f_logo" />
          </a>
        </div>
        <p>Or copy link</p>
        <div className="d-flex gap-2 align-items-center">
          <input type="text" className="form-control" value={url} readOnly />
         <CopyText className={'btn btn-orange text-white'} text={url}/>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setOpen(false)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
    <span role='button' className='font-size-14px mt-1 d-flex align-items-center gap-1 cursor-pointer betDetailLink' onClick={() => setOpen(true)}>            <img src={shareIcon} className="img-fluid" alt="f_logo" />
          Share</span>
    </>
  );
};

export default SocialShareModal;
