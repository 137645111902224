import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import ExpandableView from '../ExpandableView';
import { CLUB_DETAILED_BET_HISTORY } from '../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom'
import Spinner from '../../../../../Components/CustLoader/Spinner';
import { getBetslipTitle } from '../../../../../Utils/sportsbook';

const conditionalRowStyles = [
    {
        when: row => row.status === "WON",
        style: {
            backgroundColor: 'rgb(82 167 123 / 90%)',
            color: 'white',
            // '&:hover': {
            // 	cursor: 'not-allowed',
            // },
        },
    },
    {
        when: row => row.status === "LOSE",
        style: {
            backgroundColor: 'rgb(255 94 79 / 48%)',
            color: 'white',
        },
    },
    {
        when: row => row.status === "REFUNDED",
        style: {
            backgroundColor: '#193438',
            color: 'white',
        },
    },
    {
        when: row => row.status === "DELETED",
        style: {
            backgroundColor: '#794ef9',
            color: 'white'
        },
    }
];

const columns = [
    {
        name: 'Participant',
        selector: row => <span className='me-2'> {row.participant} {row.status === "DELETED" && <i className="fa fa-trash font-size-16px vb-text-orange" aria-hidden="true"></i>}</span>,
        minWidth: "250px"
    },
    {
        name: 'Risk',
        selector: row => row.risking,
    },
    {
        name: 'Player Results',
        selector: row => row.result,
    },
];

export default function ExpView({ data }) {
    const [preparedData, setPreparedData] = useState(null)
    const location = useLocation()

    const getBets = async () => {
        setPreparedData(null)
        
        let club = location?.state?.club;
        if (!club) return;

        let club_id = club?.id;        
        let request_url = CLUB_DETAILED_BET_HISTORY + club_id + `?bet_type=SETTLED&report_time=${data.date}&player_id=${data.user_id}`;
        let bets = await getValuebet(request_url);
        if (bets) prepareData(bets);
    }

    const prepareData = async (bets) => {
        if (bets) {
            for (let row of bets) {
                row.participant = getBetslipTitle(row);
            }
            setPreparedData(bets)
        }
    }

    useEffect(() => {
        getBets ();
        // eslint-disable-next-line
    }, [])

    return (
        <div className='px-4 py-2 head-yellow responsive-expand'>
            {preparedData ?
                <DataTable
                    columns={columns}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpandableView />}
                    expandableRowDisabled={(row) => (row.status === 'TOTAL')}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    conditionalRowStyles={conditionalRowStyles}
                    footer={undefined}
                // noTableHead={index}
                /> :
                <Spinner />}
        </div>
    )
}
