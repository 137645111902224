import React from "react";
import { Box, Typography, Button, Avatar, Tooltip } from "@mui/material";
import { styles } from "../../Css/styles";
import { useDispatch } from "react-redux";
import { addBet, removeBet } from "../../../../redux/features/valuemarketSlice";
import { useSelector } from "react-redux";
import {
  probabilityToAmericanOdds,
  safeParseJSON,
} from "../../../../Utils/valuemakets";
import { errorNotification } from "../../../../Components/CustComp/PPHAlert";
import { OFFLINE_ODD } from "../../../../constant";

const MarketList = ({
  image,
  marketId,
  eventId,
  isSingle,
  name,
  eventName,
  outcome,
  token,
  price,
  uiType,
  onTitleClick,
}) => {
  const dispatch = useDispatch();
  // const { activeClub } = useContext(ValuebetContext);
  const valueMarketDetails = useSelector(
    (state) => state.valuemarket.valueMarketDetails
  );
    const {filterType } = useSelector((state) => state.valuemarket);
  const { market_odd_limits } = useSelector((state) => state.valuemarket.marketLimit);
  const { bets } = valueMarketDetails;
  let parsedOutcomes = [];
  let parsedOutcomePrices = [];
  let parsedOutcomeId = [];

  try {
    parsedOutcomes = safeParseJSON(outcome) || [];
  } catch {
    console.error("Invalid JSON in 'outcome'");
  }

  try {
    parsedOutcomePrices = safeParseJSON(price) || [];
  } catch {
    console.error("Invalid JSON in 'price'");
  }

  try {
    parsedOutcomeId = safeParseJSON(token) || [];
  } catch {
    console.error("Invalid JSON in 'price'");
  }

  const combinedData = parsedOutcomes.map((outcome, index) => ({
    outcome,
    price: parsedOutcomePrices[index],
    token: parsedOutcomeId[index],
  }));

  const createBetData = (item) => {
    if (bets?.length >= 6) {
      errorNotification("Bets must be less-than or equal to 6.");
      return;
    }
    const existingBet = bets.find((bet) => bet.odd_id === item.token);

    if (existingBet) {
      // If the token exists, remove the bet
      dispatch(removeBet({ odd_id: existingBet.odd_id }));
    } else {
      let odd_money = probabilityToAmericanOdds(item.price, market_odd_limits);
      let subtitle = `Moneyline ${odd_money < 0 ? "" : "+"} ${odd_money} for ${
        item.outcome
      } - ${item.name}`;

      const newBet = {
        event_id: item.eventId,
        market_id: item.marketId || "",
        odd_id: item.token || "",
        avg_price: odd_money || "",
        avg_price_actual: item.price,
        outcome: item.outcome,
        eventName: item.eventName,
        sub_title: subtitle,
        init_sub_title: subtitle,
        marketName: item.name,
      };
      dispatch(addBet(newBet));
    }
  };

  return (
    <Box
      sx={{
        ...styles.memberSection,
        mb: uiType === "OUTSIDE" ? 0 : 1,
        flexDirection: {
          xl: uiType === "OUTSIDE" || !isSingle ? "row" : "column",
          md: uiType === "OUTSIDE" || !isSingle ? "row" : "column",
          sm: uiType === "OUTSIDE" || !isSingle ? "row" : "column",
          xs: uiType === "OUTSIDE" ? "column" : "row",
        },
        alignItems: uiType === "OUTSIDE" && "start",
        gap: uiType === "OUTSIDE" && "1rem",
      }}
    >
      <Box
        onClick={onTitleClick}
        sx={{
          ...styles.top,
          alignItems: "center",
          width: {
            xs: name && !isSingle ? "90%" : "0%",
          },
        }}
      >
        {uiType === "OUTSIDE" && (
          <Avatar src={image} sx={styles.image} style={{ cursor: "pointer" }} />
        )}
        {!isSingle && (
          <Tooltip title={name} arrow>
            <Typography
              sx={{
                ...styles.memberName,
                display: "-webkit-box",
                WebkitLineClamp: 2,
                mr:'10px',
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                fontSize: uiType === "OUTSIDE" ? "16px" : "12px",
              }}
            >
              {name}
            </Typography>
          </Tooltip>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: {
            xs: "flex-end",
          },
          maxWidth: {
            xl: isSingle ? "100%" : "auto",
            md: "auto",
            lg: "auto",
            xs: "100%",
          },
          width: {
            xl: isSingle ? "100%" : "auto",
            md: "auto",
            lg: "auto",
            xs: "100%",
          },
        }}
      >
        {combinedData.map((data, index) => {
          let odd_money =
            filterType === "americanOdds"
              ? probabilityToAmericanOdds(Number(data.price), market_odd_limits)
              : `${(data.price * 100).toFixed(2)}%`;
          const isSelected = bets.some((x) => x.odd_id === data.token);
          return (
            <Box
              key={index}
              sx={styles.flexContainerStyles(isSingle, uiType)}
            >
              {
                odd_money === OFFLINE_ODD && filterType === "americanOdds" ? 
                <Button
                disabled={true}
                sx={{...styles.statusStyles(isSelected, data, uiType, isSingle),
                  backgroundColor: '#1a1919',
                  border: '0.1px solid #550e02'
                }}
              >
                -
              </Button> : <Button
                onClick={() =>
                  createBetData({ ...data, marketId, eventId, name, eventName })
                }
                // disabled={!activeClub?.level_details?.value_market} {/* TODO: Temp Unlock */}
                sx={styles.statusStyles(isSelected, data, uiType, isSingle)}
              >
                <div className="d-flex justify-content-around w-100">
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      maxWidth: "60px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {" "}
                    <Tooltip title={data.outcome} arrow>
                      {data.outcome}
                    </Tooltip>
                  </div>
                  <div>
                    {filterType === "americanOdds" &&
                      (odd_money < 0 ? "" : "+")}
                    {odd_money}
                  </div>
                </div>
              </Button>
              }
              
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default MarketList;
