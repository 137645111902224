import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from "recharts";
import {
  Button,
  ButtonGroup,
  Container,
  CircularProgress,
  Box,
} from "@mui/material";
import axios from "axios";
import moment from "moment";

const timeIntervals = [
  { label: "1H", value: "1h", fidelity: "1" },
  { label: "6H", value: "6h", fidelity: "1" },
  { label: "1D", value: "1d", fidelity: "5" },
  { label: "1W", value: "1w", fidelity: "30" },
  { label: "1M", value: "1m", fidelity: "180" },
  { label: "ALL", value: "all", fidelity: "720" },
];

const colors = ["#2d9cdb", "#ff5952", "#9b51e0", "#27ae60"];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{textAlign:'start', backgroundColor: "#212121", padding: "10px", borderRadius: "5px" }}>
        <p style={{ color: "#fff" }}><strong>Time:</strong> {payload[0].payload.time}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>
            <strong>{entry.name}:</strong> {entry.value.toFixed(2)}%
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const MarketGraphStacked = ({ tokenIds }) => {
  const [data, setData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(timeIntervals[5]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);

  const fetchData = async (interval) => {
    try {
      const allResponses = await Promise.all(
        tokenIds.map(async (tokenId) => {
          const response = await axios.get(
            `https://clob.polymarket.com/prices-history?interval=${interval.value}&market=${tokenId.id}&fidelity=${interval.fidelity}`
          );
          return response.data.history.map((point) => {
            let t = moment.utc(point.t * 1000);
            return {
              time: ["1h", "6h", "1d"].includes(interval.value) ? t.format("hh:mm a") : ["1w","1m"].includes(interval.value) ? t.format("MMM D") : t.format("MMM D"),
              [tokenId.label]: point.p * 100,
            };
          });
        })
      );
  
      const mergedData = allResponses.reduce((acc, curr) => {
        curr.forEach((point, index) => {
          if (!acc[index]) {
            acc[index] = { time: point.time };
          }
          acc[index] = { ...acc[index], ...point };
        });
        return acc;
      }, []);
      
      const newMinValue = Math.floor(
        Math.min(...mergedData.flatMap((d) => Object.values(d).slice(1))) / 5
      ) * 5;
      setMinValue(newMinValue);

      const newMaxValue = Math.ceil(
        Math.max(...mergedData.flatMap((d) => Object.values(d).slice(1))) / 5
      ) * 5;
      setMaxValue(newMaxValue);
      setData(mergedData);
      setInitialLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (tokenIds?.length > 0) {
      fetchData(selectedInterval);
    }
    // eslint-disable-next-line
  }, [selectedInterval, tokenIds]);

  return (
    <Container sx={{ textAlign: "center", marginTop: "20px", maxHeight: "500px",p:0,pr:1 }}>
      {initialLoading ? (
        <Box sx={{height:'290px',width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <CircularProgress style={{ marginTop: "20px" }} />
        </Box>
      ) : (
        <ResponsiveContainer width="100%" height={290} style={{marginLeft:'-10px'}}>
          <LineChart data={data} margin={{ top: 20, right: 0, left: 0, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#222" />
            <XAxis dataKey="time" stroke="#888" />
            <YAxis  orientation="left" domain={[minValue, maxValue]} stroke="#888" tickFormatter={(tick) => `${tick}%`} tick={{ fontSize: 12 }} interval={0} tickCount={Math.floor(maxValue / 5) + 1} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {tokenIds.map((tokenId, index) => (
              <Line
                key={tokenId.id}
                type="monotone"
                dataKey={tokenId.label}
                name={tokenId.label}
                stroke={colors[index % colors.length]}
                strokeWidth={1.5}
                dot={false}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )}
      <ButtonGroup variant="contained" sx={{ my: 3 }}>
        {timeIntervals.map((interval) => (
          <Button
            sx={{
              color: "#fff",
              background: selectedInterval.value === interval.value ? "#d52b00" : "#363535",
              borderColor: "#999!important",
              "&:hover": {
                background: "#d52b00",
              },
            }}
            key={interval.value}
            onClick={() => setSelectedInterval(interval)}
          >
            {interval.label}
          </Button>
        ))}
      </ButtonGroup>
    </Container>
  );
};

export default MarketGraphStacked;
