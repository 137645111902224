import React, { useCallback } from "react";
import { Box, IconButton, InputBase } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { styles } from "../../Css/styles";
import { setFilterToggle, setMobileScreen } from "../../../../redux/features/valuemarketSlice";
import { useDispatch, useSelector } from "react-redux";
import ToggleFilterButton from "../../Layout/Common/ToggleFilterButton";
import OrderDropdown from "../../Layout/Common/OrderDropdown";
import FavouriteEvent from "../../Layout/Common/FavouriteEvent";
import { debounce } from "../../../../Utils/comman";

const SearchBar = ({ handleSearch }) => {
  const dispatch = useDispatch();
  const { toggleFilter, EventLoading } = useSelector(
    (state) => state.valuemarket
  );

  // eslint-disable-next-line
  const debouncedSearch = useCallback(debounce((value) => handleSearch(value), 1000),[])

  const toggle = () => {
    dispatch(setFilterToggle(!toggleFilter)); // Toggling the value
    dispatch(setMobileScreen("step1"))
  };

  return (
    <Box sx={styles.searchBar}>
      <IconButton onClick={toggle}>
        <FilterListIcon sx={{ color: "white", fontSize: '1.3rem' }} />
      </IconButton>
      <Box
        sx={styles.searchWrapper}
      >
        <SearchIcon sx={{ color: "white", marginRight: 1, fontSize: '1.3rem' }} />
        <InputBase
          placeholder="Search by market"
          onChange={(e) => debouncedSearch(e.target.value)}
          inputProps={{ "aria-label": "search by market" }}
          sx={{ color: "white", flex: 1 }}
          disabled={EventLoading}
        />
      </Box>
      <Box sx={{display:'flex',gap:'0.5rem',justifyContent:'space-between',width:{
        xs:'100%',
        sm:'auto',
        xl:'auto'
      },'> button,div' :{
        flexGrow:2,
        borderRadius:'5px'
      }}}>
      <OrderDropdown/>
      <ToggleFilterButton/>
      <FavouriteEvent/>
      </Box>
    </Box>
  );
};

export default SearchBar;