import React, { useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { getAllMarketFiltersList, getAllMarketSlugList } from "../../../../redux/services/valuebetmarketServices";
import { useDispatch, useSelector } from "react-redux";
import { setMainCategory, setSearchKeyword, setSubCategory } from "../../../../redux/features/valuemarketSlice";
import { styles } from "../../Css/styles";
import Spinner from "../../../../Components/CustLoader/Spinner";

export default function Index({mobView}) {
  const dispatch = useDispatch();
  const { toggleFilter, filtersData, mainCategory } = useSelector((state) => ({
    toggleFilter: state.valuemarket.toggleFilter,
    filtersData: state.valuemarket.filtersData.data,
    mainCategory: state.valuemarket.mainCategory,
    subCategory:state.valuemarket.subCategory
  }));
  
  const getSlugByFilterSlug = (slug) => {
    if(slug !== mainCategory) dispatch(setSubCategory(slug))
    dispatch(setMainCategory(slug))
    dispatch(setSearchKeyword(slug))
  };

  useEffect(()=>{
    if(!filtersData){
      dispatch(getAllMarketFiltersList());
    }
  // eslint-disable-next-line
  },[filtersData])

  useEffect(()=>{
    if(mainCategory){
      dispatch(getAllMarketSlugList(`?tag=${mainCategory}&status=active`))
    }
  // eslint-disable-next-line
  },[mainCategory])

  return (
    <Grid
      item
      sx={styles.filterStyles(toggleFilter, mobView)}
      mt="71px"
    >
      {Array.isArray(filtersData) && filtersData.length > 0 ? (
        filtersData.map((item, index) => (
          <Button
            onClick={() => getSlugByFilterSlug(item.slug)}
            sx={{
              background: "transparent",
              justifyContent: "start",
              display: "flex",
              alignItems: "start",
              textTransform:'uppercase',
              color: item.slug === mainCategory ? '#d52b00' : "white",

              "&:hover": {
                background: "transparent",
                color: "red",
              },
            }}
            key={index}
          >
            <Typography
              variant="body"
              sx={{
                fontSize: "14px",
                textAlign: "start",
                whiteSpace: "nowrap",
                fontFamily: "inherit",
                textTransform: "uppercase",
              }}
            >
              {item.label}
            </Typography>
          </Button>
        ))
      ) : (
        <Spinner/>
      )}
    </Grid>
  );
}