import moment from "moment";
import {
  getValuebet,
  PLAYER_MARKET_LIMIT,
  POLYMARKETS_FILTERS_LISTS,
  POLYMARKETS_LISTS,
  POLYMARKETS_LISTS_SEARCH,
  POLYMARKETS_SLUG_LISTS,
  postValuebet,
  VALUEMARKET_FAV,
  VALUEMARKET_FAV_lIST,
} from "../../API-Handle/services/auth_curd";
import { clearBets, setClearEventData, setEventsCheck, setEventsData, setFavEvents, setFiltersData, setMainCategory, setMarketLimitData, setSubCatList, setSubCategory } from "../features/valuemarketSlice";


export const getAllMarketSlugList = (param) => {
  return async (dispatch) => {
    let URL = `${POLYMARKETS_SLUG_LISTS}${param}`;
    let res = await getValuebet(URL);
    dispatch(setSubCatList({ data: res }));
  };
};

export const getPlayerMarketLimitList = (param) => {
  return async (dispatch) => {
    let URL = `${PLAYER_MARKET_LIMIT}${param}`;
    let res = await getValuebet(URL);
    if (res) dispatch(setMarketLimitData(res));
  };
};

export const getFavEventList = () => {
  return async (dispatch) => {
    let URL = `${VALUEMARKET_FAV_lIST}`;
    let res = await getValuebet(URL);
    dispatch(setFavEvents(res));
  };
};

export const PostFavEvent = (param) => {
  return async (dispatch, getState) => {
    let URL = `${VALUEMARKET_FAV}${param}`;
    let res = await postValuebet(URL);
    if (res) {
      const favEvents = getState().valuemarket.favEvents;
      const updatedEvents = favEvents.includes(param)
        ? favEvents.filter(eventId => eventId !== param)
        : [...favEvents, param];
      dispatch(setFavEvents(updatedEvents));
    }
  };
};

export const clearAllData = () => (dispatch) => {
  dispatch(clearBets());
  dispatch(setClearEventData());
  dispatch(setSubCategory(''));
  dispatch(setMainCategory('all'));
};

export const getEventsList = (params, append = false) => {
  return async (dispatch) => {
    try {
      let queryString = new URLSearchParams(params).toString();
      if(queryString.includes('tag_slug=all')) queryString = queryString.replaceAll('tag_slug=all','')
      const searchParams = ['_c', '_q=', '_s', '_p'];
      const isSearchQuery = searchParams.every(param => queryString.includes(param));
      const baseURL = isSearchQuery ? POLYMARKETS_LISTS_SEARCH : POLYMARKETS_LISTS;
      const URL = `${baseURL}?${queryString}`;
      const res = await getValuebet(URL);
      const initialData = isSearchQuery ? res.events : res;
      const data = initialData.filter((item) => item.active && !item.closeditem?.endDate && item.active && !item.closed && moment.utc(item.endDate).isSameOrAfter(moment.utc(), 'day'));
      dispatch(setEventsCheck(data.length === 0));
      dispatch(setEventsData({ data, append }));
    } catch (error) {
      console.error("Error fetching events:", { params, append, error });
      return;
    }
  };
};

export const getAllMarketFiltersList = () => {
  return async (dispatch) => {
    let URL = `${POLYMARKETS_FILTERS_LISTS}`;
    let res = await getValuebet(URL);
    dispatch(setFiltersData({ data: res }));
  };
};
