import React, { useContext, useState } from 'react'
import { errorNotification, successNotification } from '../../../../../../../Components/CustComp/PPHAlert';
import ValuebetContext from '../../../../../../../context/Valuebet/ValuebetContext';
import { UPDATE_PLAYER_BET_LIMIT_URL, postValuebet } from '../../../../../../../API-Handle/services/auth_curd';
import LimitTable from '../../Comman/LimitTable';

export default function SportLimits({item, club, limits, setUpdatedLimits, toggleModal}) {
    const { fetchSingleClub } = useContext(ValuebetContext);
    const [loader, setLoader] = useState(false);

    const savePlayerBetLimits = async () => {
        setLoader(true)
        let is_error = false;
        limits.forEach((it) => {
            if (it.max_bet_all === "" || it.max_bet_all < 50 ||
                it.max_bet_live === "" || it.max_bet_live < 50 ||
                it.max_parlay_all === "" || it.max_parlay_all < 50 ||
                it.max_parlay_live === "" || it.max_parlay_live < 50) {
                is_error = true;
            }
        })

        if (is_error) {
            setLoader(false);
            errorNotification("Please enter valid inputs. Inputs are should not be less-than 50.");
            return;
        }
        let data = {
            "club_id": club.id,
            "user_id": item.id,
            "bet_limits_enabled": item.bet_limits_enabled,
            "bet_limits": limits
        }

        let res = await postValuebet(`${UPDATE_PLAYER_BET_LIMIT_URL}`, data)
        if (res) {
            successNotification(res.message);
            fetchSingleClub(club.id);
        }
        setLoader(false)
    }

    return (
        <LimitTable
            height={220}
            loader={loader}
            limits={limits}
            setUpdatedLimits={setUpdatedLimits}
            saveLimits={savePlayerBetLimits}
            toggleModal={toggleModal}
        />
    )
}
