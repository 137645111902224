import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";
import ValuebetContext from "../../context/Valuebet/ValuebetContext";
import user4 from "../../Static/UserManagement/images/common/default-user-avtar.png";
import { BASEURL } from "../../API-Handle/config";
import Support from "../PublicModals/Support";
import ValuebetFAQ from "../PublicModals/ValuebetFAQ";
import UserGuide from "../PublicModals/UserGuide";
import News from "../PublicModals/News";
import AffiliateFAQ from "../PublicModals/AffiliateFAQ";
import ChallengeFAQ from "../PublicModals/ChallengeFAQ";
import Alert from "../PublicModals/Alerts";
import { clearQueryStringFromURL } from "../../Utils/comman";
import { LockOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMessanger } from '../../redux/services/chatServices';

const ProfileMenu = () => {
  const [menu, setMenu] = useState(false);
  const [telModal, setTelModal] = useState(false);
  const [faqModal, setFaqModal] = useState(false);
  const [faqChgModal, setFaqChgModal] = useState(false);
  const [faqAffiliateModal, setFaqAffiliateModal] = useState(false);
  const [guideModal, setGuideModal] = useState(false);
  const [newsModal, setNewsModal] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const { userDetails, accountLogout } = useContext(ValuebetContext);
  const { isClubChatOpen } = useSelector(state => state.clubChat)
  const primaryClubItem = useSelector((state) => state.valuebet?.primaryClubItem);

  const toggle = () => setMenu(prev => !prev);
  const telToggle = () => setTelModal(prev => !prev);
  const faqToggle = () => setFaqModal(prev => !prev);
  const faqChgToggle = () => setFaqChgModal(prev => !prev);
  const faqAffiliateToggle = () => setFaqAffiliateModal(prev => !prev);
  const guideToggle = () => setGuideModal(prev => !prev);
  const newsToggle = () => setNewsModal(prev => !prev);

  const isViewed = () => {
    // return (userDetails?.is_news_available || !localStorage.getItem('is_affiliate_faq_viewed'))
    return userDetails?.is_news_available;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if ('NEWS' === params.get('type')) {
      newsToggle();
      clearQueryStringFromURL();
    }
  }, [location]);

  const openChat = () => {
    // if (!userDetails?.level_details?.p2p_message){ // TODO: Temp Unlocked
    dispatch(toggleMessanger(primaryClubItem, isClubChatOpen));
    // }else{ // TODO: Temp Unlocked
    //   errorNotification("Upgrade Club Level to Access")
    // }
  }

  return (
    <>
      <Alert />
      <ValuebetFAQ isVisible={faqModal} modToggle={faqToggle} />
      <ChallengeFAQ isVisible={faqChgModal} modToggle={faqChgToggle} />
      <AffiliateFAQ isVisible={faqAffiliateModal} modToggle={faqAffiliateToggle} />
      <UserGuide isVisible={guideModal} modToggle={guideToggle} />
      <Support telModal={telModal} telToggle={telToggle} />
      <News newsModal={newsModal} newsToggle={newsToggle} />
      <Dropdown isOpen={menu} toggle={toggle} className="list-inline-item" tag="li">
        <DropdownToggle className="arrow-none nav-user" tag="a">
          {userDetails?.profile_picture ? (
            <img
              src={BASEURL + userDetails?.profile_picture}
              alt="user"
              className="rounded-circle"
            />
          ) : (
            <img src={user4} alt="user" className="rounded-circle" />
          )}
          {isViewed() ? <span className="live-dot text-danger">●</span> : null}
        </DropdownToggle>
        <DropdownMenu className="profile-dropdown">
          <Link role="menuitem" className="dropdown-item" to="/profile-setting">
            Settings
          </Link>
          <Link role="menuitem" className="dropdown-item" to="/new-club">
            Create Club
          </Link>
          <Link role="menuitem" className="dropdown-item" to="/club-join">
            Join Club
          </Link>
          <div role="button" className="dropdown-item d-flex align-items-center" onClick={openChat}>
            <span>Message</span>
            {!userDetails?.level_details?.p2p_message && (
              <div className="d-flex align-items-center ms-1">
                <LockOutlined fontSize="small" className="icon-bet-no" />
                <div className="text-warning font-size-10px mb-1 ms-1">Preview</div>
              </div>
            )}
          </div>
          <div className="dropdown-divider"></div>
          <span role="button" className="dropdown-item" onClick={() => window.location = '/shop'}>
            Shop
          </span>
          <Link role="menuitem" className="dropdown-item" to="/order-history">
            Order History
          </Link>
          <Link role="menuitem" className="dropdown-item" to="/affiliate-reports">
            Affiliate Reports
          </Link>
          <Link role="menuitem" className="dropdown-item" to="/prices">
            Prices
          </Link>
          <div className="dropdown-divider"></div>
          <span className="dropdown-item" role="button" onClick={faqToggle}>
            Valuebet FAQ
          </span>
          <span className="dropdown-item" role="button" onClick={faqChgToggle}>
            Challenge FAQ
          </span>
            <span className="dropdown-item" role="button" onClick={faqAffiliateToggle} id="affiliate_faq_2">
            Affiliate FAQ{!localStorage.getItem('is_affiliate_faq_viewed') ? <sup className="font-size-15px text-danger">●</sup> : null}
          </span>
          <span className="dropdown-item" role="button" onClick={guideToggle}>
            User Guide
          </span>
          <span className="dropdown-item" role="button" onClick={telToggle}>
            Support
          </span>
          <span className="dropdown-item" role="button" onClick={newsToggle}>
            News{userDetails?.is_news_available ? <sup className="font-size-15px text-danger">●</sup> : null}
          </span>
          <div className="dropdown-divider"></div>
          <span className="dropdown-item text-danger" onClick={accountLogout} role="button">
            Logout
          </span>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ProfileMenu;