import React, { useEffect } from 'react'
import { Modal } from "reactstrap"

export default function AffiliateFAQ({ isVisible, modToggle }) {

    useEffect(() => {
        if (isVisible && !localStorage.getItem('is_affiliate_faq_viewed')) {
            localStorage.setItem('is_affiliate_faq_viewed', true);
        }
    }, [isVisible])

    return (
        <Modal isOpen={isVisible} toggle={modToggle} className="modal-dialog-centered modal-xl">
            <div className="modal-header bg-vb-dark">
                <h5 className="modal-title my-0">Affiliate FAQ</h5>
                <button onClick={modToggle} type="button" className="text-white btn-close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className='modal-body letter-space'>
                <h6 className='vb-text-orange'>What is the Valuebet.App Affiliate Network?</h6>
                <p>The Valuebet.App Affiliate Network is our way of <span className='text-warning'>awarding our members</span> for helping us grow. We award users with Valuebet Coins if their referred affiliates either win Valuebet Coins in our challenges or spends Valuebet Coins to upgrade their accounts or Clubs.</p>

                <h6 className='vb-text-orange'>Who can be an Affiliate?</h6>
                <p><span className='text-warning'>Anyone who has an account</span> at Valuebet.App is already set up to be an Affiliate. There are no tedious Sign-Up processes or additional requirements. All you have to do is start referring others using your User ID.</p>

                <h6 className='vb-text-orange'>How does it work?</h6>
                <p>Give whoever is creating an account at Valuebet.App <span className='text-warning'>your User ID as reference.</span>Once registered, that user is your Tier 1 Affiliate. If they refer someone, then that someone becomes their Tier 1 Affiliate, and your Tier 2 Affiliate, and so forth.</p>

                <h6 className='vb-text-orange'>How many Tier Affiliates can we have?</h6>
                <p>Everyone can have <span className='text-warning'>up to Tier 5</span> Affiliates. This means you refer Tier 1, Tier 1 referrals is your Tier 2, Tier 2 referrals is your Tier 3, Tier 3 referrals is your Tier 4, Tier 4 referrals is your Tier 5.</p>

                <h6 className='vb-text-orange'>What are the Affiliate Awards?</h6>
                <p>We are currently <span className='text-warning'>awarding users Valuebet Coins</span> if anyone in their Affiliate Network wins Valuebet Coins from any of our Challenges or spends Valuebet Coins to upgrade their accounts or Clubs.</p>

                <h6 className='vb-text-orange'>How much are the Affiliate Awards?</h6>
                <p>We are currently awarding <span className='text-warning'>10% of an Affiliate's winnings or spendings.</span> So if anyone in your Affiliate Network wins or spends 100 Valuebet Coins, you are automatically awarded 10 Valuebet Coins.</p>

                <h6 className='vb-text-orange'>Are there any additional requirements?</h6>
                <p>Yes! You are <span className='text-warning'>required to participate by submitting a bet</span> in a challenge in order to receive any affiliate awards for that challenge. If you do not submit a bet for a Daily Challenge, you will not receive any affiliate awards for that Daily Challenge. There are no requirements for receiving awards from a referral’s spending.</p>

                <h6 className='vb-text-orange'>How can I see my Reports?</h6>
                <p>Awards from Valuebet Coins earned through a referral’s winnings <span className='text-warning'>can be seen under the Rewards tab</span> in the Freeplay Challenge. Commission from Valuebet Coins earned through a referral’s spending can be seen under Affiliate Reports in the User Menu.</p>
            </div>
        </Modal>
    )
}
