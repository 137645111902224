export const styles = {
  box: {
    overflowY: "auto",
    pl:1,
    pb:1,
  },
  cardStyle: {
    borderRadius: "8px",
    minHeight: "200px",
    border:'1px solid #222',
    background:
      "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
    maxHeight: "200px",
    position: "relative",
  },
  top: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "center",
  },
  image: {
    objectFit: "cover",
    height: "38px",
    width: "38px",
    borderRadius: "50%",
    border: "1px solid red",
  },
  subImage: {
    objectFit: "cover",
    height: "28px",
    width: "28px",
    borderRadius: 0,
  },
  
  title: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    fontFamily: "Ubuntu",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  footPart: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    background: "#222",
    px: "1rem",
    py: "0.2rem",
    borderTop:'5px solid #0d0d0d',
    button: {
      display: "flex",
      gap: "1rem",
    },
  },
  scrollbar: {
    mt: 2,
    overflowY: "auto",
    maxHeight: "200px",
    "&::-webkit-scrollbar": {
      width: "0",
    },
  },
  buttonScroll: {
    display: "flex",
    overflowX: "scroll",
    gap: 1,
    maxWidth:'100%',
    whiteSpace: "nowrap",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": { display: "none" },
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#0b0b0b",
    borderRadius: "4px",
    padding: "4px 8px",
    width: "100%",
    mb: '10px',
    gap: 1,
    color: "white",
    flexWrap:{
      xs:'wrap'
    },
    justifyContent:{
      xs:'space-between'
    }
  },
  selectBox: {
    color: "white",
    maxHeight:'46px',
    backgroundColor: "#2c2c2c",
    borderRadius: "4px",
    ".MuiOutlinedInput-notchedOutline": { border: "none" },
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#000000",
    borderRadius: "4px",
    padding: "2px 8px",
    flex: 1,
    minWidth:{
      xs:'auto',
      sm:'auto'
    }
  },
  footArea: { display: "flex", justifyContent: "space-between" },
  iconRyt: { display: "flex", gap: "1rem" },
  memberSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1,
  },
  memberName: { fontSize: "12px", fontFamily: "inherit" },
  statusClr: {
    borderRadius: 1,
    fontSize: "12px",
    px: 1.5,
    py: 0.5,
    fontFamily: "inherit",
  },
  yesColor: {
    background: "#00ff0036",
    color: "#00ff00",
  },
  noColor: {
    background: "#e03f0036",
    color: "#e03f00",
  },
  toggler: {
    backgroundColor: "#2c2c2c",
    borderRadius: "4px",
    ".MuiToggleButton-root": {
      color: "white",
    },
  },
  BetContainerStyles : (mobView) => ({
    display: {
    xs: mobView  ? 'block' : 'none',
    sm: mobView ? 'block' : 'none',
    md:mobView ? 'block' : 'none',
    lg: "block",
    xl: "block",
    }
  }),
  responsiveContainerStyles : (toggleFilter, mobView) => ({
    width: {
      xs: "100%",
      sm: "100%",
      md: "100%",
      lg: toggleFilter ? "60%" : "71%",
      xl: toggleFilter ? "60%" : "71%",
    },
    display: {
      xs: mobView ? "block" : "none",
      sm: mobView ? "block" : "none",
      md: mobView ? "block" : "none",
      lg: "block",
      xl: "block",
    },
    maxHeight:'calc(100vh - 75px)',
    overflow:'auto'
    // transition: "max-width 1s ease, visibility 0.1ms ease, opacity 1s ease-out",
  }),  
  filterStyles : (toggleFilter, mobView) => ({
    p: 2,
    maxWidth: {
      md: toggleFilter ? "100%" : "100%",
      sm: toggleFilter ? "100%" : "100%",
      xs: toggleFilter ? "100%" : "100%",
      xl: toggleFilter ? "30%" : "0",
      lg: toggleFilter ? "30%" : "0",
    },
    overflow: "hidden",
    width: {
      xl: toggleFilter ? "auto" : "0",
      lg: toggleFilter ? "auto" : "0",
      md: toggleFilter ? "100%" : "100%",
      sm: toggleFilter ? "100%" : "100%",
      xs: toggleFilter ? "100%" : "100%",
    },
    display: {
      xl: toggleFilter ? "block" : "none",
      lg: toggleFilter ? "block" : "none",
      md: mobView ? "block" : "none",
      sm: mobView ? "block" : "none",
      xs: mobView ? "block" : "none",
    },
  }),
  flexContainerStyles : (isSingle,uiType) => ({
    display: "flex",
    gap: 1,
    alignItems: "center",
    maxWidth: (isSingle || uiType === "OUTSIDE") ? "100%" : "auto",
    width: (isSingle || uiType === "OUTSIDE") ? "100%" : "auto",
  }),
  statusStyles : (isSelected,data, uiType, isSingle) => ({
    ...styles.statusClr,
    color:
      isSelected || data.outcome === "Yes"
        ? "#fff"
        : data.outcome === "No"
        ? "#fff"
        : "#fff",
    backgroundColor: isSelected
      ? "#d52b00" : 'trasnparent',
    border:'0.5px solid #d52b00',  
    borderRadius: "5px",
    padding: "2px",
    fontSize: uiType === "OUTSIDE" || isSingle ? "14px" : "12px",
    minWidth: {
      xs: uiType === "OUTSIDE" || isSingle ? "100%" : "80px",
      sm: uiType === "OUTSIDE" || isSingle ? "110px" : "90px",
      md: uiType === "OUTSIDE" || isSingle ? "120px" : "90px",
      lg: uiType === "OUTSIDE" || isSingle ? "130px" : "110px",
      xl: uiType === "OUTSIDE" || isSingle ? "140px" : "120px",
    },
    minHeight: uiType === "OUTSIDE" || isSingle ? "40px" : "auto",
    maxWidth: isSingle ? "100%" : "auto",
    width: isSingle ? "100%" : "auto",
    "&:hover": {
      background: data.outcome === "Yes" ? "#e64800" : "#e64800",
      color: "#fff",
    },
  }),
  menuButtonStyles : (slug, item,subSlug) => ({
    flexShrink: 0,
    background: (slug || subSlug) === item.slug ? "#e03f00" : "#252222",
    color: "#fff",
    fontFamily: "inherit",
    minWidth:'auto',
    borderRadius:'5px',
    fontSize:'13px',
    padding:'2px 9px',
    maxHeight:'25px',
    textTransform:'uppercase',
    "&:hover": {
      background: "#e03f00",
    },
  }),
  buttonStyles : (slug, mainCategory) => ({
    flexShrink: 0,
    background: slug === "all" || (mainCategory === slug) ? "#e03f00" : "#252222",
    color: "#fff",
    fontFamily: "inherit",
    borderRadius:'5px',
    fontSize:'13px',
    padding:'2px 9px',
    maxHeight:'25px',
    textTransform:'uppercase',
    "&:hover": {
      background: "#e03f00",
    },
  }),
  filterOption:{borderRadius:'5px',borderColor:'#d52b00',color:'#fff',textTransform:'capitalize',background:'#ffffff14',
    '&:hover':{
      background:'#2c2928',
      borderColor:'#2c2928',
      color:'#fff'
    }
  },
  summaryTitle:{
    width: "100%",
    ".MuiAccordionSummary-content": {
      width: "100%",
      "> div": {
        width: "100%",
      },
    },
  },
  detailsHeader:{
    borderRadius: "0 0 10px 10px",
    bgcolor: "#0b0b0b",
    color: "#fff",
    height: "100%",
    // height: {
    //   xs: "calc(100vh - 190px)",
    //   sm: "calc(100vh - 190px)",
    //   md: "calc(100vh - 160px)",
    //   lg: "calc(100vh - 160px)",
    //   xl: "calc(100vh - 160px)",
    // },
    // overflowY: "auto",
    p: 1,
    width: "100%",
  },
  detailsTitle:{
    fontSize: "16px",
    mb: "0.5rem",
    alignItems: "center",
    fontFamily: "inherit",
    gap: "0.2rem",
    display: "flex",
  },
  detailsWrapper:{
    ml: 0,
    overflowY: "auto",
    pr: 0,
    width: {
      xs: "100%",
      sm: "100%",
      lg: "100%",
    },
  },
  ruleWrapper:{
    maxWidth: "100%",
    marginTop: "1rem",
    bgcolor: "transparent",
    color: "white",
    p: 1,
  },
  listCardWrapper:{
    width: "100%",
    pt: 1,
    pr: 1,
    border: "0.1px solid #d52b00",
    borderRadius: "5px"
  },
  listCardBorderWrapper:{
    maxHeight: {
      xs: "calc(100vh - 250px)",
      sm: "calc(100vh - 210px)",
      lg: "calc(100vh - 168px)",
      md: "calc(100vh - 168px)",
      xl: "calc(100vh - 168px)",
    },
    overflowY: "auto",
    pr:1
    }
};
