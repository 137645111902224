import React, { useEffect, useState } from 'react'
import BetCard from './BetCard'
import BetBottom from './BetBottom'
import { WinToRisk } from '../../../../../Utils/sportsbook';

export default function Index({ betDetails, market_bet_limits }) {
    const { max_bet_all } = market_bet_limits
    const [totalMaxRisking, setTotalMaxRisking] = useState(0);

    useEffect(()=>{
        let total_max_risking = 0;
        betDetails?.bets.forEach(item => {
            if (item.avg_price < 0) {
                total_max_risking += WinToRisk(item.avg_price, max_bet_all);
            }else{
                total_max_risking += max_bet_all;
            }
        });
        setTotalMaxRisking(total_max_risking);
        // eslint-disable-next-line
    }, [betDetails])

    return (
        <>
            {
                betDetails?.bets.map(item => (
                    <BetCard item={item} 
                    key={item.odd_id} 
                    totalMaxRisking={totalMaxRisking} 
                    betDetails={betDetails}
                    market_bet_limits={market_bet_limits}
                    />
                ))
            }
            <BetBottom details={betDetails} market_bet_limits={market_bet_limits}/>
        </>
    )
}
