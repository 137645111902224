import { Grid, Card, CardContent, Box, Avatar, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import { applyVigTax, safeParseJSON } from "../../../../Utils/valuemakets";
import React, { useContext } from "react";
import { styles } from "../../Css/styles";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";
import MarketList from "../../Layout/Common/MarketList";
import { useNavigate } from "react-router";
import ToggleFav from "./ToggleFav";

export default function EventCard({ item }) {
    const { activeClub } = useContext(ValuebetContext);
    const navigate = useNavigate();

    return (
        <Grid item lg={6} xl={6} md={6} sm={12} xs={12} key={item.id} sx={styles.box}>
            <Card sx={{ ...styles.cardStyle, "&:hover": { cursor: "pointer", 'span.MuiTypography-heading6': { color: "#d52b00" } } }}>
                <CardContent sx={{ p: 2 }}>
                    <Box sx={styles.top}>
                        <Avatar
                            src={item.image}
                            sx={{ ...styles.image, borderRadius: '50%', border: '1px solid #d52b00' }}
                            style={{ cursor: "pointer" }}
                        />
                        <Typography sx={styles.title} variant="heading6" 
                        
                        onClick={() => navigate(`/value-markets/${item.id}`,{state: { club: activeClub } })}
                        >
                            {item.title}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            ...styles.scrollbar,
                            mt: item?.markets?.length === 1 ? "58px" : "15px",
                            maxHeight: item?.markets?.length <= 1 ? "100%" : "100px",
                        }}
                    >
                        {item.markets
                            .map((row) => {
                                const formattedPrice = applyVigTax(row, activeClub)
                                return { ...row, formattedPrice };
                            })
                            .sort((a, b) => {
                                const pricesA = a.formattedPrice ? safeParseJSON(a.formattedPrice) : [];
                                const pricesB = b.formattedPrice ? safeParseJSON(b.formattedPrice) : [];
                                return Math.max(...pricesA.map(Number)) - Math.max(...pricesB.map(Number));
                            })
                            .filter((row) => row.active && !row.closed)
                            .map((row, index) => (
                                <MarketList
                                    key={index}
                                    image={row.image}
                                    marketId={row.id}
                                    eventId={item.id}
                                    isSingle={item?.markets?.length === 1}
                                    name={row.groupItemTitle}
                                    eventName={item.title}
                                    outcome={row.outcomes}
                                    token={row.clobTokenIds}
                                    price={row.formattedPrice}
                                    uiType={"INSIDE"}
                                />
                            ))}
                    </Box>
                </CardContent>

                <Box sx={styles.footPart}>
                    <Box sx={{...styles.footArea,'&:hover':{
                        background:'transparent!important'
                    }}}>
                        <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
                            <Typography sx={{ fontSize: "12px", fontFamily: "inherit", display: "flex", gap: "0.2rem", alignItems: 'center' }}>
                                <CalendarMonthIcon fontSize="medium" />
                                Est. End Date : {moment.utc(item.endDate).format("MMM Do YYYY")}
                            </Typography>
                        </Box>
                        <ToggleFav eventId={item.id}/>
                    </Box>
                </Box>
            </Card>
        </Grid>
    )
}