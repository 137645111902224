import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import ExpandableView from '../../../../../../../Sportbook/Reports/Bets/ExpandableView';
import moment from 'moment'
import { PLAYER_BET_HISTORY } from '../../../../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../../../../API-Handle/services/auth_curd';
import { APP_OFFSET } from '../../../../../../../../constant';
import Spinner from '../../../../../../../../Components/CustLoader/Spinner';
import { getBetslipTitle } from '../../../../../../../../Utils/sportsbook';

const columns = [
    {
        name: 'Participant',
        selector: row => row.participant,
        sortable: true,
        minWidth: "240px"
    },
    {
        name: 'Risk',
        selector: row => row.risking,
        sortable: true
    },
    {
        name: 'To Win',
        selector: row => row.winnings,
        sortable: true
    }
];

export default function MemberUnsettled({ club, item }) {
    const [preparedData, setPreparedData] = useState(null)
    const [dynamicColumns, setDynamicColumns] = useState(columns);
    
    const getBets = async () => {
        setPreparedData(null)
        let club_id = club?.id;
        if (!club_id) return;
        let request_url = PLAYER_BET_HISTORY + club_id + `?bet_type=UNSETTLED&player_id=${item?.id}`;
        let bets = await getValuebet(request_url);
        prepareData(bets);
    }

    const prepareData = (data) => {
            if (!data) return;
            let totals = { risking: 0, winnings: 0 };
            let formattedData = data.map(row => {
                totals.risking += row.risking;
                totals.winnings += row.winnings;
        
                return {
                    ...row,
                    participant: getBetslipTitle(row),
                    final_date: moment.utc(row.created_at).utcOffset(APP_OFFSET).format('L LT') + " PST"
                };
            });
        
            totals.risking = totals.risking.toFixed(2);
            totals.winnings = totals.winnings.toFixed(2);
    
            setDynamicColumns(prevCols =>
                prevCols.map(col => {
                    if (col.name.startsWith("Risk")) {
                        return { ...col, name: `Risk (${totals.risking})` };
                    }
                    if (col.name.startsWith("To Win")) {
                        return { ...col, name: `To Win (${totals.winnings})` };
                    }
                    return col;
                })
            );
        
            setPreparedData(formattedData);
        };

    useEffect(() => {
        getBets()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {preparedData ?
                <DataTable
                    columns={dynamicColumns}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpandableView isModal={true} />}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    footer={null}
                /> :
                <Spinner />}
        </>
    )
}
