import React, { useState } from 'react'
import { Modal } from 'reactstrap';
import { General, Limits, History, Transaction, Wager } from './SettingTabs';
import { LockOutlined } from '@mui/icons-material';

const TabItems = ({ activeTab, onClick, title, isLock }) => {
    return (<li className="nav-item">
        <span className={activeTab ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={onClick}>
            {/* // TODO: Temp Unlocked */}
            {/* {isLock && <LockOutlined fontSize='small' className='locked-features' />} {title} */}
            <div className='d-flex'>
                {title} {isLock && <div className='d-flex'><LockOutlined fontSize='small' className='locked-features' /> <div className="text-warning font-size-10px mb-1">Preview</div></div>}
            </div>
        </span>
    </li>)
}

const renderItems = (id, club, item) => {
    switch (id) {
        case 1:
            return <General club={club} item={item} />
        case 2:
            return <Limits club={club} item={item} />
        case 3:
            return <Wager club={club} item={item} />
        case 4:
            return <Transaction club={club} item={item} />
        case 5:
            return <History club={club} item={item} />
        // case 6:
        //     return <InternalLogs club={club} item={item} />
        default:
            return <General />
    }
}

export default function MemerSettings({ item, club, memberSettingsModal, toggleSettingModal }) {
    const [activeTab, setActiveTab] = useState(1)
    return (
        <>
            <Modal isOpen={memberSettingsModal} toggle={toggleSettingModal} className="modal-dialog-centered modal-xl">
                <div className="modal-header">
                    <h5 className="modal-title my-0"> Member Settings</h5>
                    <button onClick={toggleSettingModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="card mb-0 text-start bg-vb-dark p-0">
                    <div className="card-header seperater">
                        <ul className="nav nav-tabs card-header-tabs">
                            <TabItems title={"General"} activeTab={activeTab === 1} onClick={() => setActiveTab(1)} />
                            <TabItems title={"Limits"} activeTab={activeTab === 2} onClick={() => setActiveTab(2)} isLock={!club?.level_details?.custom_limits} />
                            <TabItems title={"Wager"} activeTab={activeTab === 3} onClick={() => setActiveTab(3)} />
                            <TabItems title={"Transaction"} activeTab={activeTab === 4} onClick={() => setActiveTab(4)} />
                            <TabItems title={"History"} activeTab={activeTab === 5} onClick={() => setActiveTab(5)} />
                            {/* <TabItems title={"Internal Logs"} activeTab={activeTab === 6} onClick={() => setActiveTab(6)} /> */}
                        </ul>
                    </div>
                    <div className="card-body">
                        {renderItems(activeTab, club, item)}
                    </div>
                </div>
            </Modal>
        </>
    )
}
